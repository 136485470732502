import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer, BadgeThemeColor } from '@progress/kendo-react-indicators';
import { menuIcon } from "@progress/kendo-svg-icons";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar
} from '@progress/kendo-react-layout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import logo_white from '../../assets/images/logo_white.png';
import { CommonResources, Resources } from '../../config/CommonResources';
import { DARK_MODE } from '../../config/Constants';
import useAppRoutes from '../helpers/RouteHook';
import { TOGGLE_NOTIFICATIONS, TOGGLE_SETTINGS, TOGGLE_SIDEBAR } from '../../store/actions/navActions';
import { RootState } from '../../store/reducer/rootReducer';
import { OnDemandIcon } from '../helpers/IconLoader';
export interface IAppNavBarProps {
  isAuthenticated: boolean;
  onSignIn: () => void;
}
function AppNavBar(props: IAppNavBarProps): React.ReactElement {
  const navigate = useNavigate();
  const { BaseRoutes } = useAppRoutes();
  const selectSecurity = (state: RootState) => state.security;
  const selectTheme = (state: RootState) => state.nav.theme;
  const selectNotifications = (state: RootState) => state.nav.notifications;
  const currentSecurity = useSelector(selectSecurity);
  const currentTheme = useSelector(selectTheme);
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  const handleClickClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_SIDEBAR });
  };
  const handleClickSettings = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_SETTINGS });
  };
  const handleClickNotifications = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_NOTIFICATIONS });
  };

  const AppInfo = () => {
    const applogo = currentTheme === DARK_MODE ? logo_white : logo;
    return (
      <AppBarSection className="d-none d-xs-none d-sm-block">
        <Link to={BaseRoutes.Home} className="k-appbar-brand">
          <img src={applogo} className="brand-logo me-1" alt={Resources.ApplicationName} />
          <div className="k-appbar-brand-name">
            {/* <h3>{Resources.ApplicationName}</h3> */}
            {Resources.ApplicationDescription && <h5 className="mb-0 d-none d-sm-none d-md-block" style={{fontSize: '18px'}}>{Resources.ApplicationDescription}</h5>}
          </div>
        </Link>
      </AppBarSection>
    );
  };


  const NotificationInfo = () => {
    const themeColor: BadgeThemeColor = notifications.length > 0 ? 'secondary' : 'info';
    return (
      <AppBarSection>
        <a href="/help?Section=6" onClick={(e) => {
            e.preventDefault();
            navigate('/help?Section=6');
          }}
          style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer', paddingRight: '10px' }}
          className="nav-bar-links"
        >
          {CommonResources.Button_ContactSupport}
        </a>
        <Button className="k-icon-button" fillMode="flat" onClick={handleClickNotifications}>
          <BadgeContainer>
            <OnDemandIcon icon={notifications.length > 0 ? 'bell' : ['far', 'bell']} size="lg" fixedWidth={true} />
            <Badge themeColor={themeColor} size="small">
              {notifications.length}
            </Badge>
          </BadgeContainer>
        </Button>
      </AppBarSection>
    );
  };
  const UserAvatar = () => {
    const buttonInfo = currentSecurity.userInfo?.avatar ? (
      <Avatar rounded="full" type="image">
        <img src={currentSecurity.userInfo?.avatar} alt={currentSecurity.userInfo?.displayName} />
      </Avatar>
    ) : (
      <Avatar rounded="full" type="icon" themeColor={'light'}>
        <OnDemandIcon icon="user" />
      </Avatar>
    );
    return (
      <AppBarSection>
        <Button fillMode="flat" onClick={handleClickSettings}>
          {buttonInfo}
        </Button>
      </AppBarSection>
    );
  };
  const AppSeparator = () => {
    return (
      <AppBarSection>
        <span className="k-appbar-separator" />
      </AppBarSection>
    );
  };
  const SignInInfo = () => {
    return (
      <AppBarSection>
        <Button fillMode="flat" className="k-icon-button" onClick={() => props.onSignIn()}>
          <OnDemandIcon icon="sign-in-alt" />
        </Button>
      </AppBarSection>
    );
  };
  const MenuSection = () => {
    return (
      <AppBarSection>
        {currentSecurity.profile?.admin && <Button className="menu-svg-icon" svgIcon={menuIcon} fillMode={'flat'} onClick={handleClickClose} />}
      </AppBarSection>
    );
  };
  if (!props.isAuthenticated)
    return (
      <AppBar positionMode={'fixed'}>
        <AppInfo />
        <AppBarSpacer />
        <AppSeparator />
        <SignInInfo />
      </AppBar>
    );
  if (props.isAuthenticated && currentSecurity.isAuthorized)
    return (
      <>
        <AppBar positionMode={'fixed'}>
          <MenuSection />
          <AppInfo />
          <AppBarSpacer />
          <NotificationInfo />
          {/* <HelpInfo /> */}
          <AppSeparator />
          <UserAvatar />
        </AppBar>
      </>
    );
  return (
    <AppBar positionMode={'fixed'}>
      <MenuSection />
      <AppInfo />
      <AppBarSpacer />
      <AppSeparator />
      <UserAvatar />
    </AppBar>
  );
}
export default AppNavBar;
