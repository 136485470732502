import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ThemeSelector from './components/themes/ThemeSelector';
import store from './store/reduxStore';
import { LIGHT_MODE } from './config/Constants';

(async () => {
  const currentTheme = localStorage.getItem('currentTheme');
  if (!currentTheme || currentTheme === LIGHT_MODE) {
    await import('./components/themes/LightTheme');
  } else {
    await import('./components/themes/DarkTheme');
  }
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeSelector>
          <App />
        </ThemeSelector>
      </Provider>
    </BrowserRouter>,
  );
})();
