import { DocumentNode, gql } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Guid } from 'guid-typescript';
import { EditionMode } from '../../config/Constants';
import { IEnumProps } from '../../config/Enums';
import { TwoLetterAcronym } from '../helpers/Utils';
import { EntityType } from './GraphQLModel';
import DOMPurify from 'dompurify';

export const emptyPagedData = { edges: [], totalCount: 0 };
export enum MenuFlagEnum {
  Admin = 0,
  MarketAdmin = 1 << 0,
  Owner = 1 << 1,
  All = ~(~0 << 1),
}
export interface IMenuItemProps {
  id: number;
  title: string;
  description?: string;
  icon: IconProp;
  isnew?: boolean;
  route: string;
  parentId?: number;
  flags: MenuFlagEnum;
  admin: boolean;
  dashboard: boolean;
}

export interface IQueryProps {
  query: DocumentNode;
  queryNode: string;
  rootNode?: string | undefined;
  textField: string;
  keyField: string;
}
export interface IConfigProps {
  type: EntityType;
  area: string;
  display: string;
  displayPlural: string;
  entityName: string;
  apiNode: string;
  path: string;
  queryPageNode: string;
  queryIdNode: string;
  queryNode: string;
  displayAccessor: string;
  parentPath?: string;
  parentIdAccessor?: string;
  parentDisplayAccessor?: string;
}
export interface IResponseProps {
  isError: boolean;
  message?: string;
  totalRecords?: number;
}
export interface IDisplayProps {
  id: string;
  display: string | undefined;
}
export interface IMarketDisplayProps extends IDisplayProps {
  isoCode: string;
}
export interface ISelectMenuItem {
  title: string;
  value?: string;
}
export interface IRadioEnumProps {
  label: string;
  value: string;
}
export interface IPaginationProps {
  skip: number;
  take: number;
}
export interface IOrderProps {
  field: string;
  order: string;
}
export interface IWhereProps {
  field: string;
  operator: string;
  value?: string | null | boolean;
  values?: string[];
  isNot?: boolean;
}
export interface ISystemInfoProps {
  applicationName: string;
  version: string;
  server?: string;
  environment: string;
  securityManagerApiUrl?: string;
  securityManagerUrl?: string;
  supportEmail?: string;
}
export interface IUserInfoProps {
  displayName?: string;
  avatar?: string;
  jobTitle?: string;
  givenName?: string;
  officeLocation?: string;
  mobilePhone?: string;
  id?: string;
  mail?: string;
  userPrincipalName?: string;
  preferredLanguage?: string;
}
export interface IMarketDisplayProps extends IDisplayProps {
  isoCode: string;
}

export const gql_Types = gql`
  enum SourceType {
    API_DESCRIPTOR
    API_GRAPH_QL
    SQL_DATABASE
    HIVE
    AZURE_STORAGE
    NONE
  }
  enum SourceTarget {
    USER
    GROUP
    ENTITY
    OTHERS
  }
  enum EntityStatus {
    ACTIVE
    INACTIVE
    DISABLE
  }
  enum RoleType {
    STANDARD
    MARKET_ADMINISTRATOR
    ADMINISTRATOR
    NONE
  }
  enum DirectionType {
    ASC
    DESC
  }

  enum OperatorType {
    CONTAINS
    STARTS_WITH
    ENDS_WITH
    EQUAL
    GREATER_THAN
    GREATER_THAN_OR_EQUAL
    LESS_THAN
    LESS_THAN_OR_EQUAL
    IN_LIST
    EMPTY
  }
  enum OpLogic {
    AND
    OR
  }
  type OrderByParam {
    field: String!
    order: DirectionType
  }

  type FilterByParam {
    field: String!
    IsNot: Boolean
    operator: OperatorType
    logic: OpLogic
    value: String
    values: String
    filters: [FilterByParam]
  }

  type BasicKey {
    key: String!
    permissionId: ID
    permissionKeyId: ID
    entityId: String
  }
`;

export interface ICoreEntityProps {
  id: string;
  code: string;
  sourceReference?: string;
  inactive?: Date | undefined;
  status: IEnumProps;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  display?: string | undefined;
}
export type DefaultExcludedFields = 'createdOn' | 'createdBy' | 'modifiedOn' | 'modifiedBy' | 'display' | 'status';
export interface ICoreEntityInputProps extends Pick<ICoreEntityProps, 'id' | 'code' | 'sourceReference' | 'inactive'> {
  status: string;
}
export interface INameEntityProps extends ICoreEntityProps {
  name: string;
  description?: string;
  longName?: string;
}
export interface INameEntityInputProps extends ICoreEntityInputProps {
  name: string;
  description?: string;
  longName?: string;
}
export function getMutation(mutation: any): any {
  return {
    variables: mutation,
  };
}
export const getCoreEntityMutation = (dataItem: ICoreEntityProps, mode: EditionMode): ICoreEntityInputProps => {
  return Object.assign({
    id: mode === EditionMode.Create ? Guid.create().toString() : dataItem.id,
    code: mode === EditionMode.Create ? (dataItem.code ? dataItem.code : 'XX') : dataItem.code,
    status: dataItem.status.code,
    inactive: dataItem.inactive ? dataItem.inactive : undefined,
    sourceReference: dataItem.sourceReference,
  });
};
export const getNameEntityMutation = (dataItem: INameEntityProps, mode: EditionMode): INameEntityInputProps => {
  return Object.assign({
    id: mode === EditionMode.Create ? Guid.create().toString() : dataItem.id,
    code: dataItem.code ? dataItem.code : TwoLetterAcronym(dataItem.name),
    status: dataItem.status.code,
    name: dataItem.name,
    longName: dataItem.longName,
    inactive: dataItem.inactive ? dataItem.inactive : undefined,
    description: dataItem.description ? DOMPurify.sanitize(dataItem.description) : undefined,
    sourceReference: dataItem.sourceReference,
  });
};
