import { EditionMode } from '../../config/Constants';
import { EnumType, valueOf, defaultValueOf, ControlTypeEnum, IEnumProps } from '../../config/Enums';
import { Resources } from '../../config/CommonResources';
import {
  getCoreEntityMutation,
  getMutation,
  getNameEntityMutation,
  IConfigProps,
  ICoreEntityInputProps,
  ICoreEntityProps,
  IDisplayProps,
  INameEntityInputProps,
  INameEntityProps,
} from './GraphQLShared';
import { EntityType } from './GraphQLModel';
import DOMPurify from 'dompurify';
import { TwoLetterAcronym } from '../helpers/Utils';

export const embedReportFiltersConfig: IConfigProps = {
  type: EntityType.EmbedReportFilter,
  area: 'Administration',
  display: Resources.EmbedReportFilter,
  displayPlural: Resources.EmbedReportFilters,
  entityName: 'EmbedReportFilter',
  apiNode: 'embedReportFilters',
  path: '/filters',
  queryPageNode: 'embedReportFilters_page',
  queryIdNode: 'embedReportFilter',
  queryNode: 'embedReportFilters',
  displayAccessor: 'display',
  parentPath: '/products',
  parentDisplayAccessor: 'report.display',
  parentIdAccessor: 'report.id',
};
export const getEmbedReportFilterInitialValues = (dataItem: any): IEmbedReportFilterProps => {
  if (!dataItem) {
    const newObject = {} as IEmbedReportFilterProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    newObject.type = defaultValueOf(EnumType.ControlType);
    newObject.scope = defaultValueOf(EnumType.FilterScope);
    newObject.rls = false;
    newObject.isNot = false;
    newObject.order = 0;
    return newObject;
  } else {
    return Object.assign({}, dataItem as IEmbedReportFilterProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
      type: valueOf(dataItem.type, EnumType.ControlType),
      scope: valueOf(dataItem.scope, EnumType.FilterScope),
      field: {
        display: dataItem.field,
        id: dataItem.sourceReference ?? `${dataItem.catalog}.${dataItem.table}.${dataItem.field}`,
        table: dataItem.table,
        catalog: dataItem.catalog,
      },
      defaultValueEnum:
        dataItem.type == ControlTypeEnum.DateRangeExtended || ControlTypeEnum.MonthRangeExtended
          ? valueOf(dataItem.defaultValue, EnumType.DateRanges)
          : null,
      defaultValueDrop: dataItem.type == ControlTypeEnum.DropDown ? { id: dataItem.defaultValue, display: dataItem.defaultValue } : null,
      defaultValueMultiSelect: (dataItem.type == ControlTypeEnum.MultiSelect || dataItem.type == ControlTypeEnum.MultiSelectAll) && dataItem.defaultValue ? dataItem.defaultValue.split(";").map(x => { return { id: x, display: x } }):[],
    });
  }
};
export const getEmbedReportFilterMutation = (dataItem: IEmbedReportFilterProps, mode: EditionMode): any => {
  const data: IEmbedReportFilterInputProps = Object.assign({}, getCoreEntityMutation(dataItem, mode), {
    type: dataItem.type.code,
    scope: dataItem.scope.code,
    rls: dataItem.rls == true,
    isNot: dataItem.isNot == true,
    cascadeField: dataItem.cascadeField,
    cascadeFilterId: dataItem.cascadeFilter && dataItem.cascadeFilter?.id ? dataItem.cascadeFilter?.id : undefined,
    defaultValue: dataItem.defaultValue,
    expression: dataItem.expression,
    catalog: dataItem.catalog,
    field: dataItem.field.display ?? '',
    filterName: dataItem.filterName,
    method: dataItem.method ?? '',
    order: parseInt(dataItem.order.toString()),
    pages: dataItem.pages,
    reportId: dataItem.report.id,
    table: dataItem.table,
  });
  return getMutation(data);
};
export interface IEmbedReportFilterInputProps extends ICoreEntityInputProps {
  type: string;
  scope: string;
  rls: boolean;
  isNot: boolean;
  cascadeField?: string | undefined;
  cascadeFilterId?: string | undefined;
  defaultValue?: string;
  expression?: string;
  field: string;
  catalog: string;
  filterName: string;
  method: string;
  order: number;
  pages?: string;
  reportId: string;
  table: string;
}
export interface IFieldFilterProps extends IDisplayProps {
  catalog: string;
  table: string;
}
export interface IEmbedReportFilterProps extends ICoreEntityProps {
  type: IEnumProps;
  scope: IEnumProps;
  isNot: boolean;
  rls: boolean;
  cascadeField?: string;
  cascadeFilter?: IDisplayProps;
  defaultValue?: string;
  defaultValueEnum?: IEnumProps;
  defaultValueDrop?: IDisplayProps;
  defaultValueMultiSelect?:IDisplayProps;
  expression?: string;
  field: IFieldFilterProps;
  catalog: string;
  filterName: string;
  method: string;
  order: number;
  pages?: string;
  report: IDisplayProps;
  table: string;
  formatPages?: [IDisplayProps];
}

export const embedReportPagesConfig: IConfigProps = {
  type: EntityType.EmbedReportPage,
  area: 'Administration',
  display: Resources.EmbedReportPage,
  displayPlural: Resources.EmbedReportPages,
  entityName: 'EmbedReportPage',
  apiNode: 'embedReportPages',
  path: '/pages',
  queryPageNode: 'embedReportPages_page',
  queryIdNode: 'embedReportPage',
  queryNode: 'embedReportPages',
  displayAccessor: 'display',
  parentPath: '/products',
  parentDisplayAccessor: 'report.display',
  parentIdAccessor: 'report.id',
};
export const getEmbedReportPageInitialValues = (dataItem: any): IEmbedReportPageProps => {
  if (!dataItem) {
    const newObject = {} as IEmbedReportPageProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    newObject.type = defaultValueOf(EnumType.PageType);
    newObject.netGross = defaultValueOf(EnumType.NetGross);
    newObject.order = 0;
    return newObject;
  } else {
    return Object.assign({}, dataItem as IEmbedReportPageProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
      type: valueOf(dataItem.type, EnumType.PageType),
      netGross: valueOf(dataItem.netGross, EnumType.NetGross),
    });
  }
};
export const getEmbedReportPageMutation = (dataItem: IEmbedReportPageProps, mode: EditionMode): any => {
  const data: IEmbedReportPageInputProps = Object.assign({}, getCoreEntityMutation(dataItem, mode), {
    type: dataItem.type.code,
    description: dataItem.description ? DOMPurify.sanitize(dataItem.description) : undefined,
    displayName: dataItem.displayName,
    netGross: dataItem.netGross.code,
    order: parseInt(dataItem.order.toString()),
    pageCode: dataItem.pageCode,
    pageName: dataItem.pageName,
    reportId: dataItem.report.id,
    twinPageId: dataItem.twinPage && dataItem.twinPage?.id ? dataItem.twinPage?.id : undefined,
  });
  return getMutation(data);
};
export interface IEmbedReportPageInputProps extends ICoreEntityInputProps {
  type: string;
  description?: string;
  displayName: string;
  netGross: string;
  order: number;
  pageCode: string;
  pageName: string;
  reportId: string;
  twinPageId?: string | undefined;
}
export interface IEmbedReportPageProps extends ICoreEntityProps {
  type: IEnumProps;
  description?: string;
  displayName: string;
  netGross: IEnumProps;
  order: number;
  pageCode: string;
  pageName: string;
  report: IDisplayProps;
  twinPage?: IDisplayProps;
}

export const embedReportsConfig: IConfigProps = {
  type: EntityType.EmbedReport,
  area: 'Administration',
  display: Resources.EmbedReport,
  displayPlural: Resources.EmbedReports,
  entityName: 'EmbedReport',
  apiNode: 'embedReports',
  path: '/products',
  queryPageNode: 'embedReports_page',
  queryIdNode: 'embedReport',
  queryNode: 'embedReports',
  displayAccessor: 'display',
  parentPath: '/markets',
  parentDisplayAccessor: 'market.display',
  parentIdAccessor: 'market.id',
};
export const getEmbedReportInitialValues = (dataItem: any): IEmbedReportProps => {
  if (!dataItem) {
    const newObject = {} as IEmbedReportProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    newObject.type = defaultValueOf(EnumType.ProductType);
    newObject.source = defaultValueOf(EnumType.DataSourceType);
    newObject.environment = defaultValueOf(EnumType.EnvironmentType);
    newObject.rLS = false;
    newObject.netGross = false;
    newObject.embedArea = 'Reports';
    newObject.embedPage = ' ';
    return newObject;
  } else {
    return Object.assign({}, dataItem as IEmbedReportProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
      type: valueOf(dataItem.type, EnumType.ProductType),
      source: valueOf(dataItem.source, EnumType.DataSourceType),
      environment: valueOf(dataItem.environment, EnumType.EnvironmentType),
      viewMode: valueOf(dataItem.viewMode, EnumType.ViewModeType),
    });
  }
};
export const getEmbedReportMutation = (dataItem: IEmbedReportProps, mode: EditionMode): any => {
  const data: IEmbedReportInputProps = Object.assign({}, getCoreEntityMutation(dataItem, mode), {
    type: dataItem.type.code,
    environment: dataItem.environment.code,
    description: dataItem.description ? DOMPurify.sanitize(dataItem.description) : undefined,
    marketId: dataItem.market.id,
    sectionId: dataItem.section.id,
    contactOrSupportEmail: dataItem.contactOrSupportEmail,
    embedArea: dataItem.embedArea,
    embedPage: dataItem.embedPage,
    groups: dataItem.groups,
    netGross: dataItem.netGross === true,
    powerDataSourceId: dataItem.powerDataSourceId ? dataItem.powerDataSourceId : undefined,
    powerReportId: dataItem.powerReportId ? dataItem.powerReportId : undefined,
    powerRole: dataItem.powerRole,
    tags: dataItem.tags,
    powerWorkspaceId: dataItem.powerWorkspaceId ? dataItem.powerWorkspaceId : undefined,
    reportName: dataItem.reportName,
    rLS: dataItem.rLS === true,
    embedded: dataItem.embedded === true,
    customGroups: dataItem.customGroups === true,
    section: dataItem.section,
    source: dataItem.source.code,
    viewMode: dataItem.viewMode && dataItem.viewMode.code !== "" ? dataItem.viewMode.code : undefined,
    bgColor: dataItem.bgColor
  });
  return getMutation(data);
};
export interface IEmbedReportInputProps extends ICoreEntityInputProps {
  type: string;
  description?: string;
  marketId: string;
  contactOrSupportEmail?: string;
  embedArea: string;
  embedPage: string;
  groups?: string;
  netGross: boolean;
  powerDataSourceId?: string | undefined;
  powerReportId?: string | undefined;
  powerRole?: string | undefined;
  tags?: string | undefined;
  powerWorkspaceId?: string | undefined;
  reportName: string;
  rLS: boolean;
  embedded: boolean;
  customGroups: boolean;
  sectionId: string;
  source: string;
  environment: string;
  viewMode?: string | undefined;
  bgColor?: string;
}
export interface IEmbedReportProps extends ICoreEntityProps {
  type: IEnumProps;
  description?: string;
  market: IDisplayProps;
  contactOrSupportEmail?: string;
  embedArea: string;
  embedPage: string;
  groups?: string;
  netGross: boolean;
  powerDataSourceId?: string;
  powerReportId?: string;
  powerRole?: string;
  formatRoles?: [IDisplayProps];
  tags?: string;
  formatTags?: [IDisplayProps];
  securityGroups?: [IDisplayProps];
  powerWorkspaceId?: string;
  reportName: string;
  rLS: boolean;
  embedded: boolean;
  customGroups: boolean;
  section: IDisplayProps;
  source: IEnumProps;
  environment: IEnumProps;
  group?: IDisplayProps;
  report?: IDisplayProps;
  dataset?: IDisplayProps;
  viewMode?: IEnumProps;
  bgColor?: string;
}

export const marketsConfig: IConfigProps = {
  type: EntityType.Market,
  area: 'Administration',
  display: Resources.Market,
  displayPlural: Resources.Markets,
  entityName: 'Market',
  apiNode: 'markets',
  path: '/markets',
  queryPageNode: 'markets_page',
  queryIdNode: 'market',
  queryNode: 'markets',
  displayAccessor: 'display',
};
export const getMarketInitialValues = (dataItem: any): IMarketProps => {
  if (!dataItem) {
    const newObject = {} as IMarketProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    return newObject;
  } else {
    return Object.assign({}, dataItem as IMarketProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
    });
  }
};
export const getMarketMutation = (dataItem: IMarketProps, mode: EditionMode): any => {
  const data: IMarketInputProps = Object.assign({}, getCoreEntityMutation(dataItem, mode), {
    code: dataItem.code ? dataItem.code : TwoLetterAcronym(dataItem.name),
    name: dataItem.name,
    comments: dataItem.comments,
    culture: dataItem.culture,
  });
  return getMutation(data);
};
export interface IMarketInputProps extends ICoreEntityInputProps {
  name: string;
  comments?: string;
  culture: string;
}
export interface IMarketProps extends ICoreEntityProps {
  name: string;
  comments?: string;
  culture: string;
  isoCode: string;
}

export const sectionsConfig: IConfigProps = {
  type: EntityType.Section,
  area: 'Administration',
  display: Resources.Section,
  displayPlural: Resources.Sections,
  entityName: 'Section',
  apiNode: 'sections',
  path: '/sections',
  queryPageNode: 'sections_page',
  queryIdNode: 'section',
  queryNode: 'sections',
  displayAccessor: 'display',
};
export const getSectionInitialValues = (dataItem: any): ISectionProps => {
  if (!dataItem) {
    const newObject = {} as ISectionProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    newObject.order = 0;
    return newObject;
  } else {
    return Object.assign({}, dataItem as ISectionProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
    });
  }
};
export const getSectionMutation = (dataItem: ISectionProps, mode: EditionMode): any => {
  const data: ISectionInputProps = Object.assign({}, getNameEntityMutation(dataItem, mode), {
    order: dataItem.order,
  });
  return getMutation(data);
};
export interface ISectionInputProps extends INameEntityInputProps {
  order: number;
}
export interface ISectionProps extends INameEntityProps {
  order: number;
}

export const securityGroupConfig: IConfigProps = {
  type: EntityType.SecurityGroup,
  area: 'Administration',
  display: Resources.Group,
  displayPlural: Resources.Groups,
  entityName: 'Group',
  apiNode: 'securityGroups',
  path: '/tags',
  queryPageNode: 'securityGroups_page',
  queryIdNode: 'securityGroup',
  queryNode: 'securityGroups',
  displayAccessor: 'display',
};

export const embedTagsConfig: IConfigProps = {
  type: EntityType.EmbedTag,
  area: 'Administration',
  display: Resources.EmbedTag,
  displayPlural: Resources.EmbedTags,
  entityName: 'EmbedTag',
  apiNode: 'embedTags',
  path: '/tags',
  queryPageNode: 'embedTags_page',
  queryIdNode: 'embedTag',
  queryNode: 'embedTags',
  displayAccessor: 'display',
};
export const getEmbedTagInitialValues = (dataItem: any): IEmbedTagProps => {
  if (!dataItem) {
    const newObject = {} as IEmbedTagProps;
    newObject.status = defaultValueOf(EnumType.EntityStatus);
    newObject.type = defaultValueOf(EnumType.TagType);
    return newObject;
  } else {
    return Object.assign({}, dataItem as IEmbedTagProps, {
      status: valueOf(dataItem.status, EnumType.EntityStatus),
      type: valueOf(dataItem.type, EnumType.TagType),
    });
  }
};
export const getEmbedTagMutation = (dataItem: IEmbedTagProps, mode: EditionMode): any => {
  const data: IEmbedTagInputProps = Object.assign({}, getNameEntityMutation(dataItem, mode), {
    type: dataItem.type.code,
  });
  return getMutation(data);
};
export interface IEmbedTagInputProps extends INameEntityInputProps {
  type: string;
}
export interface IEmbedTagProps extends INameEntityProps {
  type: IEnumProps;
}
