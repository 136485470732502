import { IReportCatalogSummaryProps } from '../pages/catalog/ReportCatalogSummary';
import {
  IEmbedReportFilterProps,
  IEmbedReportPageProps,
  IEmbedReportProps,
  IEmbedTagProps,
  IMarketProps,
  ISectionProps,
} from './GraphQLTypes';
export type EntityTypeProps =
  | IEmbedReportFilterProps
  | IEmbedReportPageProps
  | IEmbedReportProps
  | IMarketProps
  | ISectionProps
  | IEmbedTagProps
  | IReportCatalogSummaryProps;

export enum EntityType {
  EmbedReportFilter,
  EmbedReportPage,
  EmbedReport,
  Market,
  Section,
  EmbedTag,
  ReportCatalogSummary,
  SecurityGroup,
}
