import { ISecurityProfileProps, GET_SECURITY_PROFILE } from '../../components/services/GraphQLSecurity';
import { IUserInfoProps, ISystemInfoProps } from '../../components/services/GraphQLShared';

export const actions = {
  RESET_SECURITY_PROFILE: 'RESET_SECURITY_PROFILE',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_SYSTEM_INFO: 'SET_SYSTEM_INFO',
  GET_SECURITY_PROFILE_PENDING: 'GET_SECURITY_PROFILE_PENDING',
  GET_SECURITY_PROFILE_RESOLVED: 'GET_SECURITY_PROFILE_RESOLVED',
  GET_SECURITY_PROFILE_REJECTED: 'GET_SECURITY_PROFILE_REJECTED',
} as const;

export const resetSecurityProfile = () => ({ type: actions.RESET_SECURITY_PROFILE });

export const getSecurityProfile = (apolloClient: any) => async (dispatch: any) => {
  dispatch({ type: actions.GET_SECURITY_PROFILE_PENDING });
  try {
    const { data } = await apolloClient.query({ query: GET_SECURITY_PROFILE.query });
    const profile: ISecurityProfileProps = data[GET_SECURITY_PROFILE.rootNode][GET_SECURITY_PROFILE.queryNode];
    dispatch({ type: actions.GET_SECURITY_PROFILE_RESOLVED, profile });
  } catch (error) {
    dispatch({ type: actions.GET_SECURITY_PROFILE_REJECTED, error });
  }
};
export type SecurityActionTypes = SetUserInfoAction | SetSystemInfoAction;

interface SetUserInfoAction {
  type: typeof actions.SET_USER_INFO;
  value: IUserInfoProps;
}
interface SetSystemInfoAction {
  type: typeof actions.SET_SYSTEM_INFO;
  value: ISystemInfoProps;
}
export function setUserInfo(value: IUserInfoProps): SecurityActionTypes {
  return { type: actions.SET_USER_INFO, value: value };
}
export function setSystemInfo(value: ISystemInfoProps): SecurityActionTypes {
  return { type: actions.SET_SYSTEM_INFO, value: value };
}
