import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { xIcon } from "@progress/kendo-svg-icons";
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { FormDropDownList } from './panels/FormComponents';
import { requiredValidator } from '../helpers/Validators';
import { PURGE_NOTIFICATIONS, TOGGLE_SETTINGS, CHANGE_THEME, CHANGE_LANDING_PAGE } from '../../store/actions/navActions';
import { RootState } from '../../store/reducer/rootReducer';
import { CommonResources } from '../../config/CommonResources';
import { EnumType, RoleTypeEnum } from '../../config/Enums';
import { EnumTemplate } from './boxes/IconTemplate';
import { Row, Col } from 'react-bootstrap';
import { ConfirmDialog } from './panels/ConfirmDialog';
import { DARK_MODE, LIGHT_MODE, APP_LANDING_PAGES, DEFAULT_LANDING_PAGE } from '../../config/Constants';
import useAppRoutes from '../helpers/RouteHook';
import { OnDemandIcon } from '../helpers/IconLoader';
export type ISettingsProps = {
  onSignOut: () => void;
};

const Settings = (props: ISettingsProps) => {
  const { BaseRoutes } = useAppRoutes();
  const selectSecurity = (state: RootState) => state.security;
  const selectTheme = (state: RootState) => state.nav.theme;
  const selectLandingPage = (state: RootState) => state.nav.landingPage;
  const selectSettingsOpen = (state: RootState) => state.nav.settingsOpen;
  const currentTheme = useSelector(selectTheme);
  const currentLandingPage = useSelector(selectLandingPage);
  const settingsOpen = useSelector(selectSettingsOpen);
  const currentSecurity = useSelector(selectSecurity);
  const dispatch = useDispatch();
  const app_themes = [
    { id: LIGHT_MODE, text: 'Light' },
    { id: DARK_MODE, text: 'Dark' },
  ];
  const [openConfirm, setOpenConfirm] = useState(false);
  const onClickClose = (e) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_SETTINGS });
  };
  const handleSubmit = (dataItem: any) => {
    dispatch({ type: CHANGE_THEME, value: dataItem.theme.id });
    dispatch({ type: CHANGE_LANDING_PAGE, value: dataItem.landingPage.id });
    dispatch({ type: TOGGLE_SETTINGS });
  };
  const handleConfirmOK = () => {
    setOpenConfirm(false);
    dispatch({ type: PURGE_NOTIFICATIONS });
  };
  const initialForm = {
    theme: app_themes.find((p) => p.id === currentTheme) ?? LIGHT_MODE,
    landingPage: APP_LANDING_PAGES.find((p) => p.id === currentLandingPage) ?? DEFAULT_LANDING_PAGE,
  };
  const Avatar = () => {
    if (currentSecurity.userInfo?.avatar) return <img src={currentSecurity.userInfo?.avatar} alt={currentSecurity.userInfo?.displayName} />;
    return (
      <div className="no-user-image">
        <OnDemandIcon icon="user" size="2x" />
      </div>
    );
  };
  const UserRole = () => {
    if (currentSecurity.profile?.admin)
      return <EnumTemplate type={EnumType.RoleType} value={RoleTypeEnum.Administrator} size="sm" withText={true} />;
    if (currentSecurity.profile?.marketAdmin)
      return <EnumTemplate type={EnumType.RoleType} value={RoleTypeEnum.MarketAdministrator} size="sm" withText={true} />;
    if (currentSecurity.profile?.owner)
      return <EnumTemplate type={EnumType.RoleType} value={RoleTypeEnum.AppOwner} size="sm" withText={true} />;
    return <EnumTemplate type={EnumType.RoleType} value={RoleTypeEnum.Standard} size="sm" withText={true} />;
  };
  return (
    <>
      <aside className={`dialog mobile with-padding shadow ${settingsOpen === true ? 'open' : ''}`}>
        <div className="dialog-header no-border">
          <h4 className="title">{CommonResources.AccountSettings}</h4>
          <Button type="button" className="btn-close" fillMode={'flat'} svgIcon={xIcon} onClick={onClickClose} />
        </div>
        <div className="dialog-content">
          <div className="dialog-group">
            <Form
              initialValues={initialForm}
              onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form k-form-fieldset'}>
                    <Row className="settings-user">
                      <Col md={3} className="form-group">
                        <Avatar />
                      </Col>
                      <Col md={9}>
                        <small>{CommonResources.LoggedAs}</small>
                        <h4 className="mb-0">{currentSecurity.userInfo?.displayName ?? 'Stranger'}</h4>
                        {currentSecurity.userInfo?.jobTitle && <p className="mb-0">{currentSecurity.userInfo.jobTitle}</p>}
                        <UserRole />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          id={'theme'}
                          name={'theme'}
                          label={'Theme'}
                          textField="text"
                          dataItemKey="id"
                          component={FormDropDownList}
                          data={app_themes}
                          validator={requiredValidator}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <Button
                          type="button"
                          fillMode={'flat'}
                          onClick={() => {
                            formRenderProps.onFormReset();
                            dispatch({ type: TOGGLE_SETTINGS });
                          }}
                        >
                          {CommonResources.Button_Cancel}
                        </Button>
                        <Button
                          type={'submit'}
                          themeColor="primary"
                          onClick={formRenderProps.onSubmit}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          {CommonResources.Button_Save}
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
          <div className="dialog-group">
            <Link to={BaseRoutes.Help} className="k-icon-button k-button k-button-flat">
              <OnDemandIcon icon={['far', 'question-circle']} fixedWidth className="me-1" />
              {CommonResources.Help}
            </Link>
          </div>
        </div>
        <div className="dialog-footer">
          <Button onClick={() => setOpenConfirm(true)} className="k-icon-button" fillMode={'flat'}>
            <OnDemandIcon icon={['far', 'bell-slash']} fixedWidth className="me-1" />
            {CommonResources.PurgeNotifications}
          </Button>
          <br />
          <Button onClick={() => props.onSignOut()} className="k-icon-button" fillMode={'flat'}>
            <OnDemandIcon icon="sign-out-alt" fixedWidth className="me-1" />
            {CommonResources.SignOut}
          </Button>
        </div>
      </aside>
      <ConfirmDialog
        title={CommonResources.Dialog_ConfirmTitle}
        message={CommonResources.Dialog_PurgeNotifications}
        description={CommonResources.Dialog_ConfirmClick}
        onClickOk={handleConfirmOK}
        onClickCancel={() => setOpenConfirm(false)}
        visible={openConfirm}
      />
    </>
  );
};
export default Settings;
