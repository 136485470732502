import React, { useEffect, useState } from 'react';
import { DISMISS_NOTIFICATION } from '../../../store/actions/navActions';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ISignalRMessageProps, SignalRMessageType } from '../../../services/SignalRHelper';
import { CommonResources } from '../../../config/CommonResources';
import { Button } from '@progress/kendo-react-buttons';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { OnDemandIcon } from '../../helpers/IconLoader';
import { formatDate } from '@progress/kendo-intl';
import { getMinutesFromNow } from '../../helpers/Utils';

export type IMessageDataProps = ISignalRMessageProps & {
  dismiss: boolean;
};

export const getMessageIcon = (message: IMessageDataProps): JSX.Element => {
  switch (message.type) {
    case SignalRMessageType.Progress:
      return <OnDemandIcon icon="cog" size="lg" spin={message.progress < 100} className={'icon-progress'} />;
    case SignalRMessageType.Info:
      return <OnDemandIcon icon="info-circle" size="lg" className="icon-info" />;
    case SignalRMessageType.Warning:
      return <OnDemandIcon icon="exclamation-circle" size="lg" className="icon-warning" />;
    case SignalRMessageType.Error:
      return <OnDemandIcon icon="times-circle" size="lg" className="icon-error" />;
    default:
      return <OnDemandIcon icon="check-circle" size="lg" className="icon-success" />;
  }
};
export const getMessageType = (message: IMessageDataProps): string => {
  switch (message.type) {
    case SignalRMessageType.Progress:
      return CommonResources.Progress;
    case SignalRMessageType.Info:
      return CommonResources.Info;
    case SignalRMessageType.Warning:
      return CommonResources.Warning;
    case SignalRMessageType.Error:
      return CommonResources.Error;
    default:
      return CommonResources.Success;
  }
};

export type IMessageProps = {
  data: IMessageDataProps;
};
const Message = (props: IMessageProps) => {
  const [showMore, setShowMore] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const timeId = setTimeout(() => {
      dispatch({ type: DISMISS_NOTIFICATION, id: props.data.id });
    }, 86400000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const delay = formatDate(new Date(props.data.dateOccurred), 'dd/MM/yy HH:mm');
  const MinutesLeft = () => {
    if (props.data.progress == 100) return <p className="small mb-1 text-muted">Completed 100%!</p>;
    return <p className="small mb-1 text-muted">Running for {getMinutesFromNow(new Date(props.data.dateOccurred))} minutes</p>;
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch({ type: DISMISS_NOTIFICATION, id: props.data.id });
  };
  const MessageDetails = () => {
    if (!props.data.message) return null;
    if (showMore) return null;
    return <div className="mb-2 notification-message" dangerouslySetInnerHTML={{ __html: props.data.message }}></div>;
  };
  const MessagButtons = () => {
    if (!props.data.message)
      return (
        <Button size={'small'} themeColor="primary" onClick={handleClick}>
          {CommonResources.Button_Dismiss}
        </Button>
      );
    return (
      <ButtonGroup>
        <Button size={'small'} themeColor="primary" onClick={handleClick}>
          {CommonResources.Button_Dismiss}
        </Button>
        <Button size={'small'} icon={showMore ? 'plus' : 'minus'} fillMode="flat" onClick={() => setShowMore(!showMore)}>
          {showMore ? CommonResources.Button_ShowMore : CommonResources.Button_ShowLess}
        </Button>
      </ButtonGroup>
    );
  };
  const MessageActions = () => {
    if (props.data.type !== SignalRMessageType.Progress) return <MessagButtons />;
    return (
      <>
        <ProgressBar animation value={props.data.progress} style={{ width: 250 }} />
        <br />
        <MinutesLeft />
        <MessagButtons />
      </>
    );
  };
  return (
    <li className="border-bottom border-bottom-light px-4 py-3">
      <Row>
        <Col md={1}>{getMessageIcon(props.data)}</Col>
        <Col>
          <h5 className="mb-0">{getMessageType(props.data)}</h5>
        </Col>
        <Col md={5}>
          <small className="text-muted">{delay}</small>
        </Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <p className="mb-2">{props.data.title}</p>
          <MessageDetails />
          <MessageActions />
        </Col>
      </Row>
    </li>
  );
};
export default Message;
