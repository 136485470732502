import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import AppNavBar from './components/layout/AppNavBar';
import Welcome from './components/layout/Welcome';
import NavSidebar from './components/layout/NavSidebar';
import Notifications from './components/layout/Notifications';
import Settings from './components/layout/Settings';
import useAuth from './components/helpers/AuthHook';
import { ApolloProvider } from '@apollo/client';
import { getSecurityProfile, setSystemInfo, setUserInfo } from './store/actions/profileActions';
import { RootState } from './store/reducer/rootReducer';
import { asyncStates } from './config/Enums';
import { GenericLoadingPanel } from './components/layout/panels/Panels';
import useAppRoutes, { IAppRouteProps } from './components/helpers/RouteHook';
import { makeApolloClient, updateHeadersTokens } from './components/helpers/SecurityHooks';

const mapState = (state: RootState) => ({
  profileStatus: state.security.profileStatus,
});
const mapDispatch = { getSecurityProfile, setUserInfo, setSystemInfo };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const App = (props: PropsFromRedux) => {
  const { isAuthenticated, msalUserStatus, login, logout, authMiddleware, getAccessToken, user, systemInfo } = useAuth();
  const { AppRoutes } = useAppRoutes();
  const apolloClient = React.useMemo(() => makeApolloClient(authMiddleware, getAccessToken, logout), [isAuthenticated]);
  const isProfileLoading =
    msalUserStatus === asyncStates.pending ||
    (msalUserStatus === asyncStates.resolved && [asyncStates.idle, asyncStates.pending].includes(props.profileStatus as any));

  React.useEffect(() => {
    if (!isAuthenticated) return;
    props.getSecurityProfile(apolloClient);
    props.setUserInfo(user);
    props.setSystemInfo(systemInfo);
    setInterval(() => {
      updateHeadersTokens(getAccessToken);
    }, 300000);
  }, [isAuthenticated]);

  return (
    <ApolloProvider client={apolloClient}>
      <div className="app">
        <AppNavBar isAuthenticated={isAuthenticated} onSignIn={isAuthenticated ? logout : login} />
        {isProfileLoading ? (
          <GenericLoadingPanel loading={isProfileLoading} container={'app'} />
        ) : isAuthenticated ? (
          <>
            <Notifications isAuthenticated={isAuthenticated} user={user} getAccessToken={getAccessToken} />
            <Settings onSignOut={isAuthenticated ? logout : login} />
            <NavSidebar />
            <Routes>
              {AppRoutes.map((e: IAppRouteProps, i: number) => (
                <Route key={i} path={e.route} element={e.component} />
              ))}
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Welcome />} />
          </Routes>
        )}
      </div>
    </ApolloProvider>
  );
};

export default connector(App);
