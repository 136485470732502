import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import React from 'react';
import { useImmerState } from './Hooks';
import { ISystemInfoProps, IUserInfoProps } from '../services/GraphQLShared';
import { Resources } from '../../config/CommonResources';
import { apiConfig, loginRequest, msalConfig, tokenRequest } from '../../config/Config';
import { getSystemInfoDetails } from '../../services/ApiServices';
import { getUserDetails } from '../../services/GraphService';
import { asyncStates, IAsyncState } from '../../config/Enums';
import { ApolloLink } from '@apollo/client';

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  user: IUserInfoProps;
  msalUserStatus: IAsyncState;
  systemInfo: ISystemInfoProps;
  login: () => void;
  logout: () => void;
  getUserProfile: () => Promise<void>;
  getAccessToken: (scopes: string[]) => Promise<string>;
  authMiddleware: ApolloLink;
}

const isDev = process.env.NODE_ENV === 'development';

const useAuth = (): AuthComponentProps => {
  const emptyUser = {} as IUserInfoProps;
  const [state, setState] = useImmerState({
    error: null,
    isAuthenticated: false,
    user: emptyUser,
    msalUserStatus: asyncStates.idle,
    authMiddleware: new ApolloLink((operation, forward) => {
      return forward(operation);
    }),
    systemInfo: {
      applicationName: Resources.ApplicationName,
      environment: '',
      version: '',
    } as ISystemInfoProps,
  });
  const { error, isAuthenticated, user, msalUserStatus, systemInfo, authMiddleware } = state;

  const publicClientApplication = React.useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          authority: msalConfig.authority,
          clientId: msalConfig.clientId,
          redirectUri: msalConfig.redirectUri,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
              if (containsPii) {
                return;
              }
              switch (level) {
                case LogLevel.Error:
                  if (isDev) console.error(message);
                  return;
                case LogLevel.Info:
                  if (isDev) console.info(message);
                  return;
                case LogLevel.Verbose:
                  if (isDev) console.debug(message);
                  return;
                case LogLevel.Warning:
                  if (isDev) console.warn(message);
                  return;
              }
            },
            piiLoggingEnabled: false,
          },
          windowHashTimeout: 60000,
          iframeHashTimeout: 6000,
          loadFrameTimeout: 0,
        },
      }),
    [],
  );

  React.useEffect(() => {
    (async () => {
      await publicClientApplication.initialize();
      publicClientApplication
        .handleRedirectPromise()
        .then((response) => {
          if (response !== null) {
            getUserProfile();
          } else {
            const accounts = publicClientApplication.getAllAccounts();
            if (accounts && accounts.length > 0) {
              getUserProfile();
            } else {
              login();
            }
          }
        })
        .catch((e: string | Error | any) => {
          console.log(e);
          setState({ ...state, error: e.errorMessage });
        });
    })();
  }, []);

  const getAccessToken = async (scopes: string[]): Promise<string> => {
    try {
      const accounts = publicClientApplication.getAllAccounts();
      if (accounts.length <= 0) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await publicClientApplication.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });

      return silentResult.accessToken;
    } catch (e: any) {
      const isInteractionRequired = (error: Error) => {
        if (!error.message || error.message.length <= 0) {
          return false;
        }
        return (
          error.message.indexOf('invalid_grant') > -1 ||
          error.message.indexOf('consent_required') > -1 ||
          error.message.indexOf('interaction_required') > -1 ||
          error.message.indexOf('login_required') > -1 ||
          error.message.indexOf('no_account_in_silent_request') > -1
        );
      };
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(e)) {
        await publicClientApplication.acquireTokenRedirect({
          scopes: scopes,
          prompt: 'select_account',
        });

        return Promise.reject('Redirecting to authentication');
      } else {
        throw e;
      }
    }
  };

  const getUserProfile = async () => {
    setState({ msalUserStatus: asyncStates.pending });
    try {
      const accessToken = await getAccessToken(tokenRequest.scopes);
      if (accessToken) {
        // Get the users profile from Graph
        const user = await getUserDetails(accessToken);
        // Get the system info from GIS Access Manager API
        const systemInfo = await getSystemInfoDetails(accessToken);
        // Attach authorization header for all axios requests
        const accessTokenAPI = await getAccessToken(apiConfig.scopes);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessTokenAPI}`;
        //axios.defaults.headers.common['Content-Type'] = 'application/json';
        const authMiddleware = new ApolloLink((operation, forward) => {
          operation.setContext({
            headers: {
              Authorization: `Bearer ${accessTokenAPI}`,
            },
          });
          return forward(operation);
        });
        setState({
          isAuthenticated: true,
          user: user,
          msalUserStatus: asyncStates.resolved,
          systemInfo: systemInfo,
          error: null,
          authMiddleware: authMiddleware,
        });
      }
    } catch (err: any) {
      console.log(err);
      setState({
        isAuthenticated: false,
        user: emptyUser,
        msalUserStatus: asyncStates.rejected,
        error: normalizeError(err),
      });
    }
  };

  const login = async () => {
    try {
      await publicClientApplication.loginRedirect(loginRequest);
    } catch (e: any) {
      console.log(e);
      setState({
        isAuthenticated: false,
        user: emptyUser,
        error: normalizeError(e),
      });
    }
  };

  const logout = () => publicClientApplication.logoutRedirect();

  return {
    error,
    isAuthenticated,
    user,
    msalUserStatus,
    systemInfo,
    login,
    logout,
    getAccessToken,
    authMiddleware,
    getUserProfile,
  };
};

const normalizeError = (error: string | Error): any => {
  let normalizedError = {};
  if (typeof error === 'string') {
    const errParts = error.split('|');
    normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
  } else {
    normalizedError = {
      message: error.message,
      debug: JSON.stringify(error),
    };
  }
  return normalizedError;
};

export default useAuth;
