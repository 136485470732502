import { gql, PureQueryOptions } from '@apollo/client';
import React from 'react';
import { DataType, ViewType } from '../../config/Constants';
import { EntityStatusEnum, EnumType } from '../../config/Enums';
import { EntityType } from '../services/GraphQLModel';
import { IConfigProps, IQueryProps } from '../services/GraphQLShared';
import { defaultPageFilter, Nullable, storagePageFilter } from './Utils';
import {
  embedReportFiltersConfig,
  embedReportPagesConfig,
  embedReportsConfig,
  embedTagsConfig,
  marketsConfig,
  sectionsConfig,
  securityGroupConfig
} from '../services/GraphQLTypes';

export enum ViewFlagEnum {
  Index = 0,
  View = 1 << 0,
  List = 1 << 1,
  Internal = 1 << 2,
  All = ~(~0 << 3),
}
export enum QueryTypeEnum {
  GetPagedList,
  GetList,
  GetDisplay,
  GetById,
}
export enum MutationTypeEnum {
  Create,
  Update,
  Duplicate,
}
export interface IFiedModelProps {
  name: string;
  type: DataType;
  mode: ViewFlagEnum;
  nullable: boolean;
  readOnly?: boolean | undefined;
  enum?: EnumType | undefined;
}
export interface IGraphQLFieldProps {
  name: string;
  type: string;
}
export interface AppModelProps {
  getFields: (type: EntityType) => Array<IFiedModelProps>;
  buildQueryByType(type: EntityType, queryType: QueryTypeEnum): IQueryProps;
  buildQuery(config: IConfigProps, queryType: QueryTypeEnum): IQueryProps;
  buildMutationByType(type: EntityType, mutationType: MutationTypeEnum): IQueryProps;
  buildMutation(config: IConfigProps, mutationType: MutationTypeEnum): IQueryProps;
  getQueryFields(type: EntityType, queryType: QueryTypeEnum): Array<string>;
  getFieldsFor: (type: EntityType, mode: ViewType) => Array<IFiedModelProps>;
  defaultRefetchQueries(config: IConfigProps, id: Nullable<string>): Array<string | PureQueryOptions>;
  defaultTypeRefetchQueries(type: EntityType, id: Nullable<string>): Array<string | PureQueryOptions>;
  coreEntityFields: Array<IFiedModelProps>;
  nameEntityFields: Array<IFiedModelProps>;
}

const useAppModel = (): AppModelProps => {
  const ViewIndexView = ViewFlagEnum.Index | ViewFlagEnum.View;
  const coreEntityFields: Array<IFiedModelProps> | [] = [
    { name: 'id', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: false },
    { name: 'code', type: DataType.String, mode: ViewIndexView, nullable: false },
    { name: 'status', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.EntityStatus },
    { name: 'sourceReference', type: DataType.String, mode: ViewIndexView, nullable: true },
    { name: 'inactive', type: DataType.DateTime, mode: ViewFlagEnum.Internal, nullable: true, readOnly: true },
    { name: 'createdOn', type: DataType.DateTime, mode: ViewFlagEnum.View, nullable: false, readOnly: true },
    { name: 'createdBy', type: DataType.String, mode: ViewFlagEnum.View, nullable: false, readOnly: true },
    { name: 'modifiedOn', type: DataType.DateTime, mode: ViewIndexView, nullable: false, readOnly: true },
    { name: 'modifiedBy', type: DataType.String, mode: ViewIndexView, nullable: false, readOnly: true },
    { name: 'display', type: DataType.String, mode: ViewIndexView, nullable: true, readOnly: true },
  ];
  const nameEntityFields: Array<IFiedModelProps> = [
    ...coreEntityFields,
    { name: 'name', type: DataType.String, mode: ViewIndexView, nullable: false },
    { name: 'description', type: DataType.String, mode: ViewFlagEnum.View, nullable: true },
    { name: 'longName', type: DataType.String, mode: ViewFlagEnum.View, nullable: true },
  ];
  const coreFilters: Array<IGraphQLFieldProps> = [
    { name: 'search', type: 'String' },
    { name: 'entityStatus', type: 'Int' },
    { name: 'where', type: '[FilterByParam]' },
    { name: 'orderBy', type: '[OrderByParam]' },
  ];
  const pagedFilters: Array<IGraphQLFieldProps> = [...coreFilters, { name: 'first', type: 'Int' }, { name: 'after', type: 'String' }];
  function parse(field: IFiedModelProps, isFilter = false): IGraphQLFieldProps {
    if (field.name == 'id') return { name: field.name, type: isFilter ? 'ID' : 'ID!' };
    switch (field.type) {
      case DataType.Integer:
        return { name: field.name, type: isFilter || field.nullable === true ? 'Int' : 'Int!' };
      case DataType.Decimal:
        return { name: field.name, type: isFilter || field.nullable === true ? 'Decimal' : 'Decimal!' };
      case DataType.Date:
      case DataType.DateTime:
        return { name: field.name, type: isFilter || field.nullable === true ? 'DateTime' : 'DateTime!' };
      case DataType.CheckBox:
        return { name: field.name, type: isFilter || field.nullable === true ? 'Boolean' : 'Boolean!' };
      case DataType.Guid:
        return { name: field.name, type: isFilter || field.nullable === true ? 'ID' : 'ID!' };
      case DataType.Enum:
        const enumString = EnumType[field.enum ?? EnumType.EntityStatus];
        return { name: field.name, type: isFilter || field.nullable === true ? enumString : `${enumString}!` };
      case DataType.IdDisplay:
      case DataType.IdDisplayIso:
        if (isFilter) return { name: `${field.name}Id`, type: 'Guid' };
        return { name: `${field.name}Id`, type: field.nullable === true ? 'ID' : 'ID!' };
      default:
        return { name: field.name, type: isFilter || field.nullable === true ? 'String' : 'String!' };
    }
  }
  const getFields = React.useCallback((type: EntityType): Array<IFiedModelProps> => {
    switch (type) {
      case EntityType.EmbedReport:
        return [
          ...coreEntityFields,
          { name: 'reportName', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'market', type: DataType.IdDisplayIso, mode: ViewIndexView, nullable: false },
          { name: 'section', type: DataType.IdDisplay, mode: ViewIndexView, nullable: false },
          { name: 'type', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.ProductType },
          { name: 'source', type: DataType.Enum, mode: ViewFlagEnum.View, nullable: false, enum: EnumType.DataSourceType },
          { name: 'environment', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.EnvironmentType },
          { name: 'viewMode', type: DataType.Enum, mode: ViewIndexView, nullable: true, enum: EnumType.ViewModeType },
          { name: 'description', type: DataType.AreaText, mode: ViewFlagEnum.View, nullable: true },
          { name: 'contactOrSupportEmail', type: DataType.Email, mode: ViewIndexView, nullable: true },
          { name: 'embedArea', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'embedPage', type: DataType.String, mode: ViewFlagEnum.View, nullable: false },
          { name: 'groups', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'powerWorkspaceId', type: DataType.Guid, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'powerReportId', type: DataType.Guid, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'powerDataSourceId', type: DataType.Guid, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'powerRole', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'bgColor', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'formatRoles', type: DataType.IdDisplayList, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'rLS', type: DataType.CheckBox, mode: ViewFlagEnum.View, nullable: false },
          { name: 'embedded', type: DataType.CheckBox, mode: ViewFlagEnum.View, nullable: false },
          { name: 'customGroups', type: DataType.CheckBox, mode: ViewFlagEnum.View, nullable: false },
          { name: 'netGross', type: DataType.CheckBox, mode: ViewFlagEnum.View, nullable: false },
          { name: 'group', type: DataType.IdDisplay, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'report', type: DataType.IdDisplay, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'dataset', type: DataType.IdDisplay, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'tags', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'formatTags', type: DataType.IdDisplayList, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'securityGroups', type: DataType.IdDisplayList, mode: ViewIndexView, nullable: true, readOnly: true },
          { name: 'new', type: DataType.CheckBox, mode: ViewIndexView, nullable: false, readOnly: true },
        ];
      case EntityType.Market:
        return [
          ...coreEntityFields,
          { name: 'name', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'culture', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'comments', type: DataType.AreaText, mode: ViewFlagEnum.View, nullable: true },
          { name: 'isoCode', type: DataType.String, mode: ViewIndexView, nullable: false, readOnly: true },
        ];
      case EntityType.Section:
        return [...nameEntityFields, { name: 'order', type: DataType.Integer, mode: ViewIndexView, nullable: false }];

      case EntityType.EmbedReportFilter:
        return [
          ...coreEntityFields,
          { name: 'report', type: DataType.IdDisplay, mode: ViewIndexView, nullable: false },
          { name: 'field', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'catalog', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'table', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'filterName', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'method', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'type', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.ControlType },
          { name: 'scope', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.FilterScope },
          { name: 'isNot', type: DataType.CheckBox, mode: ViewIndexView, nullable: false },
          { name: 'rls', type: DataType.CheckBox, mode: ViewIndexView, nullable: false },
          { name: 'cascadeField', type: DataType.String, mode: ViewFlagEnum.View, nullable: true },
          { name: 'cascadeFilter', type: DataType.IdDisplay, mode: ViewFlagEnum.View, nullable: true },
          { name: 'defaultValue', type: DataType.String, mode: ViewFlagEnum.View, nullable: true },
          { name: 'expression', type: DataType.String, mode: ViewFlagEnum.View, nullable: true },
          { name: 'order', type: DataType.Integer, mode: ViewIndexView, nullable: false },
          { name: 'pages', type: DataType.String, mode: ViewFlagEnum.Internal, nullable: true },
          { name: 'formatPages', type: DataType.IdDisplayList, mode: ViewIndexView, nullable: true, readOnly: true },
        ];
      case EntityType.EmbedReportPage:
        return [
          ...coreEntityFields,
          { name: 'report', type: DataType.IdDisplay, mode: ViewIndexView, nullable: false },
          { name: 'twinPage', type: DataType.IdDisplay, mode: ViewFlagEnum.View, nullable: true },
          { name: 'type', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.PageType },
          { name: 'pageName', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'pageCode', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'displayName', type: DataType.String, mode: ViewIndexView, nullable: false },
          { name: 'description', type: DataType.AreaText, mode: ViewFlagEnum.View, nullable: true },
          { name: 'netGross', type: DataType.Enum, mode: ViewFlagEnum.View, nullable: false, enum: EnumType.NetGross },
          { name: 'order', type: DataType.Integer, mode: ViewIndexView, nullable: false },
        ];
      case EntityType.EmbedTag:
        return [...nameEntityFields, { name: 'type', type: DataType.Enum, mode: ViewIndexView, nullable: false, enum: EnumType.TagType }];
      default:
        return [];
    }
  }, []);
  const getConfig = React.useCallback((type: EntityType): IConfigProps => {
    switch (type) {
      case EntityType.EmbedReport:
        return embedReportsConfig;
      case EntityType.Market:
        return marketsConfig;
      case EntityType.EmbedReportFilter:
        return embedReportFiltersConfig;
      case EntityType.EmbedReportPage:
        return embedReportPagesConfig;
      case EntityType.EmbedTag:
        return embedTagsConfig;
      case EntityType.Section:
        return sectionsConfig;
      case EntityType.SecurityGroup:
        return securityGroupConfig;
      default:
        return embedReportsConfig;
    }
  }, []);
  const ExcludedPageListFields = ['createdOn', 'createdBy', 'avatar', 'tags'];
  const getQueryFields = React.useCallback((type: EntityType, queryType: QueryTypeEnum): Array<string> => {
    let fields = getFields(type);
    if (queryType == QueryTypeEnum.GetPagedList) fields = fields.filter((e) => !ExcludedPageListFields.some((x) => x == e.name));
    const nameFields = fields.map((e: IFiedModelProps) =>
      e.type == DataType.IdDisplay || e.type == DataType.IdDisplayList
        ? `${e.name} { id, display }`
        : e.type == DataType.IdDisplayIso
        ? `${e.name} { id, name, isoCode, display }`
        : e.name,
    );
    return nameFields;
  }, []);
  function getMutationFields(type: EntityType): Array<IGraphQLFieldProps> {
    const fields = getFields(type);
    return fields.filter((x) => x.readOnly === undefined || x.readOnly === false).map((e: IFiedModelProps) => parse(e, false));
  }
  const getQueryFilterFields = React.useCallback((type: EntityType, paged = false): Array<IGraphQLFieldProps> => {
    const fields = getFields(type);
    const filterFields = fields
      .filter(
        (e: IFiedModelProps) =>
          e.type == DataType.IdDisplay || e.type == DataType.IdDisplayIso || e.type == DataType.Enum || e.type == DataType.CheckBox,
      )
      .filter((e: IFiedModelProps) => e.name != 'status' && !e.readOnly && !e.nullable)
      .map((e: IFiedModelProps) => parse(e, true));
    return paged ? [...pagedFilters, ...filterFields] : [...coreFilters, ...filterFields];
  }, []);
  const buildQueryByType = React.useCallback((type: EntityType, queryType: QueryTypeEnum): IQueryProps => {
    const config = getConfig(type);
    return buildQuery(config, queryType);
  }, []);
  const buildQuery = React.useCallback((config: IConfigProps, queryType: QueryTypeEnum): IQueryProps => {
    const queryFields = getQueryFields(config.type, queryType);
    const filterFields = getQueryFilterFields(config.type, queryType == QueryTypeEnum.GetPagedList);
    const filterDeclare = filterFields.map((e: IGraphQLFieldProps) => `$${e.name}:${e.type}`);
    const filterParse = filterFields.map((e: IGraphQLFieldProps) => `${e.name}:$${e.name}`);
    switch (queryType) {
      case QueryTypeEnum.GetPagedList: {
        const queryList = `query GetPaged${config.entityName}(${filterDeclare}) { ${config.queryPageNode} (${filterParse}) { edges { node {${queryFields}}}, pageInfo {hasNextPage}, totalCount }}`;
        return { query: gql(queryList), queryNode: config.queryPageNode, textField: 'display', keyField: 'id' };
      }
      case QueryTypeEnum.GetList: {
        const queryList = `query Get${config.entityName}(${filterDeclare}) { ${config.queryNode} (${filterParse}) { ${queryFields} }}`;
        return { query: gql(queryList), queryNode: config.queryNode, textField: 'display', keyField: 'id' };
      }
      case QueryTypeEnum.GetDisplay: {
        const displayfields = config.type == EntityType.EmbedTag ? 'id, display, type' : 'id, display';
        const queryList = `query Get${config.entityName}List(${filterDeclare}) { ${config.queryNode} (${filterParse}) { ${displayfields} }}`;
        return { query: gql(queryList), queryNode: config.queryNode, textField: 'display', keyField: 'id' };
      }
      default:
        const queryList = `query Get${config.entityName}ById($id: ID!) { ${config.queryIdNode}(id: $id) {${queryFields}}}`;
        return { query: gql(queryList), queryNode: config.queryIdNode, textField: 'display', keyField: 'id' };
    }
  }, []);
  const buildMutationByType = React.useCallback((type: EntityType, mutationType: MutationTypeEnum): IQueryProps => {
    const config = getConfig(type);
    return buildMutation(config, mutationType);
  }, []);
  const buildMutation = React.useCallback((config: IConfigProps, mutationType: MutationTypeEnum): IQueryProps => {
    const mutationFields = getMutationFields(config.type);
    //const queryFields = getQueryFields(config.type, QueryTypeEnum.GetById);
    const mutationDeclare = mutationFields.map((e: IGraphQLFieldProps) => `$${e.name}:${e.type}`);
    const mutationParse = mutationFields.map((e: IGraphQLFieldProps) => `${e.name}:$${e.name}`);
    switch (mutationType) {
      case MutationTypeEnum.Create: {
        const queryList = `mutation create${config.entityName}(${mutationDeclare}) { create${config.entityName} ( ${config.queryIdNode}: {${mutationParse}} ) { id }}`;
        return { query: gql(queryList), queryNode: config.queryIdNode, textField: 'display', keyField: 'id' };
      }
      case MutationTypeEnum.Update: {
        const queryList = `mutation update${config.entityName}(${mutationDeclare}) { update${config.entityName} ( ${config.queryIdNode}: {${mutationParse}} ) { id }}`;
        return { query: gql(queryList), queryNode: config.queryIdNode, textField: 'display', keyField: 'id' };
      }
      case MutationTypeEnum.Duplicate: {
        const queryList = `mutation duplicate${config.entityName}($id: ID!) { duplicate${config.entityName}(id: $id) { id }}`;
        return { query: gql(queryList), queryNode: config.queryIdNode, textField: 'display', keyField: 'id' };
      }
    }
  }, []);
  function getFieldsFor(type: EntityType, mode: ViewType): Array<IFiedModelProps> {
    const fields = getFields(type);
    switch (mode) {
      case ViewType.Index:
        return fields.filter(
          (e: IFiedModelProps) =>
            (e.mode & ViewFlagEnum.Index) === ViewFlagEnum.Index || (e.mode & ViewFlagEnum.Internal) === ViewFlagEnum.Internal,
        );
      default:
        return fields;
    }
  }
  const defaultTypeRefetchQueries = React.useCallback((type: EntityType, id: Nullable<string> = null): Array<string | PureQueryOptions> => {
    const config = getConfig(type);
    return defaultRefetchQueries(config, id);
  }, []);
  const defaultRefetchQueries = React.useCallback((config: IConfigProps, id: Nullable<string> = null): Array<string | PureQueryOptions> => {
    const result: Array<string | PureQueryOptions> = [];
    const pagedQuery = buildQuery(config, QueryTypeEnum.GetPagedList);
    const listQuery = buildQuery(config, QueryTypeEnum.GetList);
    if (id) result.push({ query: buildQuery(config, QueryTypeEnum.GetById).query, variables: { id: id } });
    result.push({ query: pagedQuery.query, variables: storagePageFilter(config.entityName) });
    result.push({ query: pagedQuery.query, variables: defaultPageFilter() });
    result.push({ query: listQuery.query, variables: { entityStatus: EntityStatusEnum.Active } });
    return result;
  }, []);
  return {
    getFields,
    buildQueryByType,
    buildQuery,
    buildMutationByType,
    buildMutation,
    getQueryFields,
    getFieldsFor,
    defaultTypeRefetchQueries,
    defaultRefetchQueries,
    coreEntityFields,
    nameEntityFields,
  };
};
export default useAppModel;
