import React from 'react';
import { Avatar } from '@progress/kendo-react-layout';
import { Colors } from '../../../config/Constants';
import { defaultTemplateValueOf, EnumType, IEnumProps, valueOf } from '../../../config/Enums';
import { formatNumber } from '@progress/kendo-intl';
import { CommonResources, Resources } from '../../../config/CommonResources';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { formatDate } from '@progress/kendo-intl';
import { Nullable } from '../../helpers/Utils';
import { Chip, ChipList } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import { OnDemandIcon } from '../../helpers/IconLoader';
import cn from 'classnames';

export interface ITemplateProps {
  type: TemplateType;
  value: any;
  size?: SizeProp;
  icon?: Nullable<IconProp>;
  className?: string;
  color?: Nullable<string>;
  route?: Nullable<string>;
  withText?: Nullable<boolean>;
}
export enum TemplateType {
  Boolean,
  Date,
  DateTime,
  TextWithIcon,
  List,
  Number,
  TextList,
  Html,
  Route,
  Tags,
  IsoCode,
  Email,
  New,
  Admin,
  AllGranted,
}
export interface ITemplateIconProps {
  display: string;
  size?: SizeProp;
  icon: IconProp;
  layerIcon?: IconProp;
  className?: string;
  color?: string;
  withText: boolean;
}
const TemplateIcon = (props: ITemplateIconProps) => {
  const getSpanClass = () => {
    if (props.size === 'sm') return `fa-layers fa-fw ${props.className}`;
    if (props.size === 'lg') return `fa-layers fa-fw fa-lg ${props.className}`;
    if (props.size === '3x') return `fa-layers fa-fw fa-4x ${props.className}`;
    return `fa-layers fa-fw ${props.className}`;
  };
  if (props.layerIcon)
    return (
      <>
        <span className={cn(getSpanClass(), { 'me-1': props.withText })}>
          <OnDemandIcon title={props.display} icon={props.icon} color={props.color} />
          <OnDemandIcon icon={props.layerIcon} transform="shrink-8" />
        </span>
        {props.withText && props.display}
      </>
    );
  return (
    <>
      <OnDemandIcon
        icon={props.icon}
        color={props.color}
        title={props.display}
        size={props.size}
        fixedWidth
        className={cn(props.className, { 'me-1': props.withText })}
      />
      {props.withText && props.display}
    </>
  );
};

function IconTemplateBase(props: ITemplateProps): React.ReactElement {
  const getAvatarSize = () => {
    if (!props.size || props.size === 'sm') return 'small';
    if (props.size === 'lg') return 'medium';
    if (props.size === '3x') return 'large';
    return 'small';
  };
  const renderTemplate = () => {
    switch (props.type) {
      case TemplateType.Admin:
        return props.value === true || props.value === 'true' ? (
          <TemplateIcon
            icon={'user-shield'}
            size={props.size}
            color={Colors.Primary_Pink}
            display={Resources.Admin}
            withText={props.withText === true}
          />
        ) : (
          <TemplateIcon icon={'house-user'} size={props.size} display={Resources.Standard} withText={props.withText === true} />
        );

      case TemplateType.AllGranted:
        return props.value === true || props.value === 'true' ? (
          <TemplateIcon
            icon="asterisk"
            size={props.size}
            color={Colors.Primary_Pink}
            display={CommonResources.Yes}
            withText={props.withText === true}
          />
        ) : (
          <TemplateIcon
            icon="crosshairs"
            size={props.size}
            color={Colors.Primary_Outlinegray}
            display={CommonResources.No}
            withText={props.withText === true}
          />
        );

      case TemplateType.New:
        return props.value === true || props.value === 'true' ? (
          <TemplateIcon
            icon="bell"
            size={props.size}
            color={Colors.Primary_Pink}
            display={Resources.New}
            withText={props.withText === true}
          />
        ) : null;

      case TemplateType.IsoCode:
        return props.value && props.value !== 'XX' ? (
          <Avatar rounded="full" type="image" size={getAvatarSize()} themeColor={'light'} border={false}>
            <img src={`/images/flags/1x1/${props.value.toLowerCase()}.svg`} alt={props.value} />
          </Avatar>
        ) : (
          <Avatar rounded="full" type="image" size={getAvatarSize()} themeColor={'light'} border={false}>
              <img src={`/images/flags/1x1/global.svg`} alt={props.value} />
          </Avatar>
        );

      case TemplateType.Number:
        return (
          <>
            {props.icon && <OnDemandIcon icon={props.icon} title={props.value} color={props.color ?? undefined} className="me-1" />}
            <span>{formatNumber(props.value, 'n0')}</span>
          </>
        );
      case TemplateType.Date:
        if (props.value)
          return <span>{new Date(props.value).getFullYear() > 2 ? formatDate(new Date(props.value), 'dd/MM/yyyy') : ' '}</span>;
        else return <span>-</span>;
      case TemplateType.Email:
        if (props.value)
          return (
            <a href={`mailto:${props.value}`}>
              <TemplateIcon icon={['far', 'envelope']} size={props.size} display={props.value} withText={props.withText === true} />
            </a>
          );
        else return null;
      case TemplateType.DateTime:
        if (props.value)
          return <span>{new Date(props.value).getFullYear() > 2 ? formatDate(new Date(props.value), 'dd/MM/yyyy HH:mm') : ' '}</span>;
        else return <span>-</span>;
      case TemplateType.List:
        if (props.value)
          return (
            <>
              {props.value?.map((e: any, i: number) => (
                <p key={i} className="mb-1">
                  {
                    <>
                      {props.icon && <OnDemandIcon icon={props.icon as IconProp} size={props.size} className="me-1" title={e.id} />}
                      <span>{e.display.trim()}</span>
                    </>
                  }
                </p>
              ))}
            </>
          );
        else return <span>-</span>;
      case TemplateType.Tags:
        if (props.value)
          return (
            <ChipList
              textField="display"
              valueField="id"
              data={props.value}
              chip={(props) => <Chip {...props} fillMode={'outline'} size="small" icon={'fa fa-tag'} />}
            />
          );
        else return <span>-</span>;
      case TemplateType.TextWithIcon:
        return (
          <>
            {props.icon && <OnDemandIcon icon={props.icon} title={props.value} color={props.color ?? undefined} className="me-1" />}
            {props.value}
          </>
        );
      case TemplateType.Route:
        if (props.route)
          return (
            <Link to={props.route} style={{ textDecoration: 'none' }}>
              {props.value}
              <OnDemandIcon icon={'arrow-right'} className="ms-1" />
            </Link>
          );
        return <span>{props.value}</span>;
      case TemplateType.Html:
        return <div className="my-2" dangerouslySetInnerHTML={{ __html: props.value }}></div>;
      case TemplateType.TextList:
        const listValues = props.value.split(';');
        const lastIndex = listValues.length - 1;
        return (
          <>
            {listValues.map((e, i) => (
              <p key={i} className={i === lastIndex ? 'mb-0' : 'mb-1'}>
                <TemplateIcon icon={props.icon ?? 'globe'} size={props.size} display={e.trim()} withText={props.withText === true} />
              </p>
            ))}
          </>
        );
      default:
        return props.value === true ? (
          <TemplateIcon
            icon={['far', 'check-circle']}
            color={Colors.SuccessColor}
            size={props.size}
            display={CommonResources.Yes}
            withText={props.withText === true}
          />
        ) : (
          <TemplateIcon
            icon={['far', 'times-circle']}
            color={Colors.AlertColor}
            size={props.size}
            display={CommonResources.No}
            withText={props.withText === true}
          />
        );
    }
  };
  return <>{renderTemplate()}</>;
}
function IconTemplatePropsAreEqual(prev: ITemplateProps, next: ITemplateProps) {
  return (
    prev.type === next.type &&
    prev.value === next.value &&
    prev.color === next.color &&
    prev.size === next.size &&
    prev.className === next.className &&
    prev.icon === next.icon &&
    prev.withText === next.withText &&
    prev.route === next.route
  );
}
export const IconTemplate = React.memo(IconTemplateBase, IconTemplatePropsAreEqual);

export interface IEnumTemplateProps {
  type: EnumType;
  value: any;
  size?: SizeProp;
  className?: string;
  color?: string;
  shortText?: boolean;
  withText: boolean;
  defaultIfEmtpy?: boolean;
}
export function EnumTemplateBase(props: IEnumTemplateProps): React.ReactElement {
  const getEnumValue = (): IEnumProps | null => {
    if (props.value == null) {
      if (props.defaultIfEmtpy === true) return defaultTemplateValueOf(props.type);
      else return null;
    }
    const enumValue = valueOf(props.value, props.type);
    if (!enumValue) {
      if (props.defaultIfEmtpy === true) return defaultTemplateValueOf(props.type);
      else return null;
    }
    return enumValue;
  };
  const notFound = (
    <>
      <OnDemandIcon
        icon={['far', 'question-circle']}
        color={Colors.Primary_Outlinegray}
        size={props.size}
        className={cn(props.className, { 'me-1': props.withText })}
        fixedWidth
      />
      {props.withText && (
        <span color={Colors.Primary_Outlinegray} style={{ fontWeight: 300 }}>
          {props.shortText == true ? 'UKN' : CommonResources.Unknown}
        </span>
      )}
    </>
  );

  const enumValue = getEnumValue();
  if (!enumValue) return notFound;
  if (!enumValue.icon) enumValue.icon = 'ban';
  return (
    <>
      <OnDemandIcon
        title={enumValue.code}
        icon={enumValue.icon}
        color={enumValue.color}
        size={props.size}
        className={cn(props.className, { 'me-1': props.withText })}
        fixedWidth
      />
      {props.withText && <span style={{ fontWeight: 300 }}>{props.shortText == true ? enumValue.shortText : enumValue.text}</span>}
    </>
  );
}
function EnumTemplatePropsAreEqual(prev: IEnumTemplateProps, next: IEnumTemplateProps) {
  return (
    prev.withText === next.withText &&
    prev.value === next.value &&
    prev.size === next.size &&
    prev.className === next.className &&
    prev.type === next.type &&
    prev.defaultIfEmtpy === next.defaultIfEmtpy &&
    prev.shortText === next.shortText
  );
}
export const EnumTemplate = React.memo(EnumTemplateBase, EnumTemplatePropsAreEqual);
