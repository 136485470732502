export const UI_RELEASE_VERSION = process.env.REACT_APP_UI_VERSION as string;

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  USER_READ_ALL: 'User.Read.All',
  USER_READ_BASIC_ALL: 'User.ReadBasic.All',
  GROUP_READ_ALL: 'Group.Read.All',
  DEFAULT: '.default',
};
export const Storage = 'https://investmentappshared.blob.core.windows.net/images/flags/4x3/ad.svg';
export const API_SCOPES = {
  API_ALL: process.env.REACT_APP_API_SCOPES_ALL as string,
  API_READ: process.env.REACT_APP_API_SCOPES_READ as string,
  API_READWRITE: process.env.REACT_APP_SCOPES_WRITE as string,
};

// For a full list of msal.js configuration parameters,
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig = {
  authority: 'https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3',
  token_endpoint: 'https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3/oauth2/v2.0/token',
  clientId: process.env.REACT_APP_MSAL_CLIENTID as string,
  redirectUri: window.location.origin,
  validateAuthority: true,
  MS_GRAPH_SCOPE: 'https://graph.microsoft.com/.default',
};

// Coordinates and required scopes for your web api
export const apiConfig = {
  resourceUri: process.env.REACT_APP_API_CONFIG_RESOURCE_URI as string,
  clientId: process.env.REACT_APP_API_CONFIG_CLIENTID as string,
  authority: 'https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3',
  endPoint: process.env.REACT_APP_API_CONFIG_ENDPOINT as string,
  swaggerEndPoint: process.env.REACT_APP_API_CONFIG_SWAGGER_ENDPOINT as string,
  graphqlEndPoint: process.env.REACT_APP_API_CONFIG_GRAPHQL_ENDPOINT as string,
  signalrEndPoint: process.env.REACT_APP_API_CONFIG_SIGNALR_ENDPOINT as string,
  storageEndpoint: process.env.REACT_APP_API_CONFIG_STORAGE_ENDPOINT as string,
  scopes: [API_SCOPES.API_ALL, API_SCOPES.API_READ, API_SCOPES.API_READWRITE],
};
/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
  scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const tokenRequest = {
  scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  forceRefresh: false, // Set this to 'true' to skip a cached token and go to the server to get a new token
};
// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const tokenAppRequest = {
  scopes: [GRAPH_SCOPES.USER_READ_BASIC_ALL],
  forceRefresh: false, // Set this to 'true' to skip a cached token and go to the server to get a new token
};
export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  ME_PHOTO: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const API_ENDPOINTS = {
  GET_SYSTEM_INFO: `${apiConfig.resourceUri}/systeminfo`,
  GET_JSON_DATA: `${apiConfig.resourceUri}/data/json`,
  GET_REPORT: `${apiConfig.resourceUri}/report/export/xls`,
  GET_EXCEL_DAATA: `${apiConfig.resourceUri}/data/xls`,
};

export const API_REPORTS = {
  ProductCatalog: 0,
  ProductSecurity: 2,
  ProductTrail: 1,
};
