import { JsonHubProtocol, HubConnection, HubConnectionState, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { IUserInfoProps } from '../components/services/GraphQLShared';
import { apiConfig } from '../config/Config';

const isDev = process.env.NODE_ENV === 'development';
const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    if (isDev) console.log('SignalR connection established');
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    if (isDev) console.error('SignalR Connection Error: ', err);
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};
export const SignalRMessageType = {
  Info: 0,
  Success: 1,
  Warning: 2,
  Error: 3,
  Progress: 4,
};
export const SignalRMessageStatus = {
  Running: 0,
  Success: 1,
  Warning: 2,
  Error: 3,
  None: 4,
};
export interface ISignalRMessageProps {
  id: string;
  title?: string;
  message?: string;
  type: number;
  status: number;
  progress: number;
  dateOccurred: Date;
}
// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection = (
  methodName: string,
  user: IUserInfoProps,
  eventHandler: (message: ISignalRMessageProps) => void,
  getAccessToken: (scopes: string[]) => Promise<string>,
): HubConnection => {
  const options = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    accessTokenFactory: () => getAccessToken(apiConfig.scopes),
  };
  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection = new HubConnectionBuilder()
    .withUrl(`${apiConfig.signalrEndPoint}?userId=${user.id}&userName=${user.userPrincipalName}`, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(isDev ? LogLevel.Warning : LogLevel.Error)
    .build();

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  connection.serverTimeoutInMilliseconds = 60000;

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log('Connection lost due to error. Reconnecting.', error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log('Connection reestablished. Connected with connectionId', connectionId);
  });

  startSignalRConnection(connection);

  connection.on(methodName, (message: ISignalRMessageProps) => {
    eventHandler(message);
  });

  return connection;
};
