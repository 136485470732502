import { useState, useEffect, useRef } from 'react';
import { produce } from 'immer';
import { useQuery } from '@apollo/client';
import { isFunction } from './Utils';

export function useStateWithEffects<T>(props: T, onValueChanged: (e: T) => void): any[] {
  // eslint-disable-next-line prefer-const
  let [value, setValue] = useState<T>(props);

  const setState = (newValue: T) => {
    value = newValue;
    setValue(value);
    onValueChanged(value);
  };

  return [value, setState];
}

export function useStickyState<T>(defaultValue: T, key: string): any[] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? (JSON.parse(stickyValue) as T) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export function useStickyStateWithEffects<T>(defaultValue: T, key: string, onValueChanged: (e: T) => void): any[] {
  const initState = (): T => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  };
  const [value, setValue] = useStateWithEffects<T>(initState(), onValueChanged);
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

interface IUsePopupProps {
  initialIsMenuOpen?: boolean;
  ignoredElementsClasses?: string[];
  shouldReactOnEsc?: boolean;
  shouldReactOnClickOutside?: boolean;
}

export const usePopup = (usePopupProps: IUsePopupProps = {}): any[] => {
  const {
    initialIsMenuOpen = false,
    ignoredElementsClasses: rawElementsClasses = [],
    shouldReactOnEsc = true,
    shouldReactOnClickOutside = true,
  } = usePopupProps;
  const ignoredElementsClasses = rawElementsClasses.concat('.k-popup');

  const [isMenuOpen, setIsMenuOpen] = useState(initialIsMenuOpen);

  useEffect(() => {
    if (!shouldReactOnEsc) return;
    const closePopup = (e: any) => {
      if (e.code === 'Escape' && isMenuOpen) setIsMenuOpen(false);
    };
    document.addEventListener('keydown', closePopup);
    return () => document.removeEventListener('keydown', closePopup);
  }, [isMenuOpen]);

  useEffect(() => {
    if (!shouldReactOnClickOutside) return;
    const closePopup = (e: any) => {
      const isClickOnPopup = ignoredElementsClasses.some((cssClass) => e.target.closest(cssClass));
      if (!isClickOnPopup && isMenuOpen) setIsMenuOpen(false);
    };
    document.addEventListener('click', closePopup);
    return () => document.removeEventListener('click', closePopup);
  }, [isMenuOpen]);

  return [isMenuOpen, setIsMenuOpen];
};

export function useImmerState<T extends object>(initialState: T): [T, any] {
  const [state, setState] = useState(initialState);

  const setImmerState = useRef((fnOrObject: any) => {
    if (isFunction(fnOrObject)) {
      const fn = fnOrObject;
      setState((curState) => produce(curState, fn));
    } else {
      const newState = fnOrObject;
      setState((curState) => ({ ...curState, ...newState }));
    }
  });

  return [state, setImmerState.current];
}

export const useSwrQuery = (query: any, params: any) => {
  const response = useQuery(query, { ...params, fetchPolicy: 'cache-and-network' });
  return {
    ...response,
    loading: !response.data && !response.error && !params.skip,
  };
};
