import { Sidebar, Menu, MenuItem, SubMenu, MenuItemStyles, menuClasses } from 'react-pro-sidebar';
import { xIcon } from "@progress/kendo-svg-icons";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import logo_white from '../../assets/images/logo_white.png';
import { CLOSE_SIDEBAR, TOGGLE_SIDEBAR } from '../../store/actions/navActions';
import { DARK_MODE } from '../../config/Constants';
import { RootState } from '../../store/reducer/rootReducer';
import { IMenuItemProps } from '../services/GraphQLShared';
import { Resources } from '../../config/CommonResources';
import { Colors } from '../../config/Constants';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import useAppRoutes from '../helpers/RouteHook';
import { OnDemandIcon } from '../helpers/IconLoader';

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const NavSidebar = () => {
  const { getNavSidebarItems } = useAppRoutes();
  const selecTheme = (state: RootState) => state.nav.theme;
  const selectSidebarOpen = (state: RootState) => state.nav.sidebarOpen;
  const theme = useSelector(selecTheme);
  const sidebarOpen = useSelector(selectSidebarOpen);
  const dispatch = useDispatch();
  const themes = {
    light: {
      sidebar: {
        backgroundColor: Colors.Primary_White,
        color: Colors.Primary_DeepNavy,
      },
      menu: {
        menuContent: Colors.Primary_White,
        icon: Colors.Primary_DeepNavy,
        hover: {
          backgroundColor: Colors.Primary_White,
          color: '#44596e',
        },
        disabled: {
          color: Colors.Primary_LightGray,
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: Colors.Primary_DeepNavy,
        color: Colors.Primary_White,
      },
      menu: {
        menuContent: Colors.Primary_DeepNavy,
        icon: Colors.Primary_Brightblue,
        hover: {
          backgroundColor: Colors.Primary_Brightblue,
          color: '#b6c8d9',
        },
        disabled: {
          color: Colors.Primary_LightGray,
        },
      },
    },
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '0.9rem',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? hexToRgba(themes[theme].menu.menuContent, 1) : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 0.1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  React.useEffect(() => {
    const closeSidebar = (e: any) => {
      if (e.code === 'Escape' && sidebarOpen) dispatch({ type: CLOSE_SIDEBAR });
    };
    document.addEventListener('keydown', closeSidebar);
    return () => document.removeEventListener('keydown', closeSidebar);
  }, [sidebarOpen]);

  const filterData = getNavSidebarItems(undefined);
  const SidebarHeader = () => {
    const applogo = theme === DARK_MODE ? logo_white : logo;
    return (
      <div
        className="ps-3 pt-3"
        style={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <Button
          className="k-icon-button me-4 close-icon"
          fillMode={'flat'}
          svgIcon={xIcon}
          onClick={() => {
            dispatch({ type: TOGGLE_SIDEBAR });
          }}
          style={{ padding: '5px'}}
        />
        <div className="d-flex flex-column">
          <img src={applogo} className="side-menu-logo" alt={Resources.AppName} />
          <h4 className="ms-1 mb-0 mt-3 side-menu-appname">{Resources.ApplicationName}</h4>
        </div>
      </div>
    );
  };
  return (
    <Sidebar
      collapsed={sidebarOpen == false}
      transitionDuration={0}
      collapsedWidth={'0px'}
      backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 0.9)}
      rootStyles={{
        color: themes[theme].sidebar.color,
        width: '25em',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SidebarHeader />
        <Menu menuItemStyles={menuItemStyles} closeOnClick={true} style={{ flexGrow: 1, marginTop: "7rem", paddingLeft: '3rem' }}>
          {filterData.map((e: IMenuItemProps, i: number) => (
            <NavSidebarMenu key={i} element={e} onClick={() => dispatch({ type: TOGGLE_SIDEBAR })} />
          ))}
        </Menu>
      </div>
    </Sidebar>
  );
};

export default React.memo(NavSidebar);

function NavSidebarMenu(props): React.ReactElement {
  const { getNavSidebarItems } = useAppRoutes();
  const navigate = useNavigate();
  const children = getNavSidebarItems(props.element.id);
  const hasChildren = children.length > 0;
  if (hasChildren)
    return (
      <SubMenu label={props.element.title} icon={<OnDemandIcon icon={props.element.icon} size={'lg'} />}>
        {children.map((e: IMenuItemProps, i: number) => (
          <NavSidebarMenu key={i} element={e} onClick={props.onClick} />
        ))}
      </SubMenu>
    );
  if (props.element.parentId)
    return (
      <MenuItem
        onClick={() => {
          navigate(props.element.route);
          props.onClick();
        }}
        rootStyles={{ paddingLeft: "60px" }}
      >
        {props.element.title}
      </MenuItem>
    );
  return (
    <MenuItem
      onClick={() => {
        navigate(props.element.route);
        props.onClick();
      }}
      icon={<OnDemandIcon icon={props.element.icon} size={'lg'} />}
    >
      {props.element.title}
    </MenuItem>
  );
}
