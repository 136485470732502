/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { DisplayBox, IBoxProps } from '../boxes/DisplayBox';
import { Button } from '@progress/kendo-react-buttons';
import { ApolloError } from '@apollo/client';
import { CommonResources } from '../../../config/CommonResources';
import { IResponseProps } from '../../services/GraphQLShared';
import { createPortal } from 'react-dom';
import { Alert, Row, Col } from 'react-bootstrap';
import cn from 'classnames';
import { parseGraphQLErrors } from '../../helpers/SecurityHooks';
import { OnDemandIcon } from '../../helpers/IconLoader';

export interface IDisplayPanelProps {
  Boxes: IBoxProps[];
  onClick: () => void;
}
export const DisplayPanel = (props: IDisplayPanelProps): React.ReactElement => {
  return (
    <>
      {props.Boxes.map((box: any, i: number) => (
        <DisplayBox key={i} {...box} title={box.label} />
      ))}
      <Row>
        <Col className="justify-content-end">
          <Button themeColor={'primary'} onClick={props.onClick}>
            {CommonResources.Button_Edit}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export interface IErrorPanelProps {
  error: ApolloError | undefined;
  onClose?: () => void;
}

export const ErrorPanel = (props: IErrorPanelProps): React.ReactElement | null => {
  if (!props.error) return null;
  const parserErros = parseGraphQLErrors(props.error);
  if (parserErros.length == 0) return null;
  return (
    <div className="k-messagebox k-messagebox-error pt-2 mt-1">
      {props.onClose && <OnDemandIcon className="k-messagebox__close-icon" icon="times" onClick={props.onClose} />}
      <h5 className="k-messagebox-error mb-0">{CommonResources.Error_Title}</h5>
      {props.error &&
        parserErros.map((me: string, i: number) => (
          <p
            className="mt-0 k-messagebox-error"
            key={i}
            dangerouslySetInnerHTML={{
              __html: me,
            }}
          ></p>
        ))}
    </div>
  );
};
export interface IResponsePanelProps {
  response: IResponseProps;
}
export const ShowResponsePanel = (props: IResponsePanelProps): React.ReactElement => {
  return (
    <Alert variant={props.response.isError ? 'danger' : 'success'} className="mt-2" role="alert">
      {props.response.isError ? (
        <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      ) : (
        <Alert.Heading>{CommonResources.Success_Title}</Alert.Heading>
      )}
      <hr />
      {props.response.message && <p className="mb-0">{props.response.message}</p>}
      {props.response.totalRecords && props.response.totalRecords > 0 && (
        <p className="mb-0">
          {CommonResources.TotalRecords}
          {props.response.totalRecords}
        </p>
      )}
    </Alert>
  );
};
export interface IAlertPanelProps {
  error: string;
}
const AlertPanelBase = (props: IAlertPanelProps): React.ReactElement => {
  return (
    <Alert variant="danger" role="alert">
      <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      <hr />
      <p>{props.error}</p>
    </Alert>
  );
};
function AlertPanelAreEqual(prev: IAlertPanelProps, next: IAlertPanelProps) {
  return prev.error === next.error;
}
export const AlertPanel = React.memo(AlertPanelBase, AlertPanelAreEqual);

export interface IWarningPanelProps {
  message: string;
}
const WarningPanelBase = (props: IWarningPanelProps): React.ReactElement => {
  return (
    <Alert variant="warning" role="alert">
      <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      <hr />
      <p>{props.message}</p>
    </Alert>
  );
};
function WarningPanelAreEqual(prev: IWarningPanelProps, next: IWarningPanelProps) {
  return prev.message === next.message;
}
export const WarningPanel = React.memo(WarningPanelBase, WarningPanelAreEqual);

export interface ISuccessPanelProps {
  message: string;
}
const SuccessPanelBase = (props: ISuccessPanelProps): React.ReactElement => {
  return (
    <Alert variant="success" role="alert">
      <Alert.Heading>{CommonResources.Success_Title}</Alert.Heading>
      <hr />
      <p>{props.message}</p>
    </Alert>
  );
};
function SuccessPanelAreEqual(prev: ISuccessPanelProps, next: ISuccessPanelProps) {
  return prev.message === next.message;
}
export const SuccessPanel = React.memo(SuccessPanelBase, SuccessPanelAreEqual);

function LoadingPanelBase() {
  return (
    <div className="k-loading-mask" style={{ minHeight: '12rem' }}>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
}
export const LoadingPanel = React.memo(LoadingPanelBase);
interface ICLoadingPanelProps {
  size?: 'sm' | 'md';
  hasTransparentBg?: boolean;
  withContainer?: boolean;
  style?: React.CSSProperties | undefined;
}
const CLoadingPanel = (props: ICLoadingPanelProps) => (
  <div className={cn('k-loading-mask-wrapper', { 'k-loading-mask-wrapper_active': props.withContainer })} style={props.style}>
    <div className="k-loading-mask">
      <div className={cn('k-loading-image', { [`k-loading-image_size-${props.size}`]: props.size })}></div>
      <div className={cn('k-loading-color', { 'k-loading-color_no-bg': props.hasTransparentBg })}></div>
    </div>
  </div>
);
export interface LoadingPanelProps extends ICLoadingPanelProps {
  loading: boolean;
  container?: string;
}
export const GenericLoadingPanel = (props: LoadingPanelProps) => {
  if (!props.loading) return null;
  const { container, ...restProps } = props;
  const content = container && document.querySelector(`.${container}`);
  const loadingPanel = <CLoadingPanel {...restProps} />;
  return content ? createPortal(loadingPanel, content) : <CLoadingPanel {...restProps} style={{ height: 100 }} />;
};
