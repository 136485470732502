import React from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from './Utils';
import Dashboard from '../layout/Dashboard';
import { EntityType } from '../services/GraphQLModel';
import { IConfigProps, IMenuItemProps, MenuFlagEnum } from '../services/GraphQLShared';
import {
  embedReportFiltersConfig,
  embedReportPagesConfig,
  embedReportsConfig,
  embedTagsConfig,
  marketsConfig,
  sectionsConfig,
} from '../services/GraphQLTypes';
import { RootState } from '../../store/reducer/rootReducer';
import { Resources } from '../../config/CommonResources';
import { EditionMode } from '../../config/Constants';

export interface RoutesComponentProps {
  getIndexRoute: (type: EntityType) => string;
  getCreateRoute: (type: EntityType) => string;
  getDetailRoute: (type: EntityType, id: string) => string;
  getRoute: (type: EntityType, mode: EditionMode) => string;
  getConfig: (type: EntityType) => IConfigProps | null;
  getComponent: (
    type: EntityType,
    mode: EditionMode,
  ) => React.LazyExoticComponent<() => React.ReactElement<any, string | React.JSXElementConstructor<any>>> | null;
  formatRoute: (route: string, mode: EditionMode) => string;
  getNavSidebarItems(parentId: Nullable<number>): IMenuItemProps[];
  getDashboardPanels(): IMenuItemProps[];
  AppRoutes: Array<IAppRouteProps>;
  BaseRoutes: any;
}
export interface IAppRouteProps {
  route: string;
  component?: React.ReactElement | undefined;
}
const Help = React.lazy(() => import('../pages/apiHelper/ApiHelperPage'));
const ReportingPage = React.lazy(() => import('../pages/reports/ReportingPage'));
const Operations = React.lazy(() => import('../pages/advanced/operations/BulkOperationsPage'));
const CrossTabBuilder = React.lazy(() => import('../pages/advanced/crosstab/CrossTabBuilder'));
const ReportViewer = React.lazy(() => import('../pages/advanced/viewer/ReportViewer'));
const EmbeddedViewer = React.lazy(() => import('../pages/advanced/embedded/EmbeddedViewer'));
const ControlPanelPage = React.lazy(() => import('../pages/advanced/controlpanel/ControlPanelPage'));
export function LazyLoader(props): React.ReactElement {
  return (
    <React.Suspense fallback={<></>}>
      <props.component />
    </React.Suspense>
  );
}
const useAppRoutes = (): RoutesComponentProps => {
  const AllToOwner = MenuFlagEnum.Admin | MenuFlagEnum.MarketAdmin | MenuFlagEnum.Owner;
  const All = MenuFlagEnum.Admin | MenuFlagEnum.MarketAdmin | MenuFlagEnum.Owner | MenuFlagEnum.All;
  const selectProfile = (state: RootState) => state.security.profile;
  const currentProfile = useSelector(selectProfile);
  const BaseRoutes = {
    Home: '/',
    Help: '/help',
    Settings: '/settings',
    Reporting: '/reporting',
    Operations: '/operations',
    ControlPanel: '/controlpanel',
    Crosstab: '/crosstab/:id',
    ReportViewer: '/reportviewer/:id',
    EmbeddedViewer: '/embedded/:id',
    HomeFilter: '/:tag',
  };
  const BaseAppRoutes = [
    { route: BaseRoutes.Home, component: <Dashboard /> },
    { route: BaseRoutes.ControlPanel, component: <LazyLoader component={ControlPanelPage} /> },
    { route: BaseRoutes.Help, component: <LazyLoader component={Help} /> },
    { route: BaseRoutes.ReportViewer, component: <LazyLoader component={ReportViewer} /> },
    { route: BaseRoutes.EmbeddedViewer, component: <LazyLoader component={EmbeddedViewer} /> },
    { route: BaseRoutes.Crosstab, component: <LazyLoader component={CrossTabBuilder} /> },
    { route: BaseRoutes.Reporting, component: <LazyLoader component={ReportingPage} /> },
    { route: BaseRoutes.Operations, component: <LazyLoader component={Operations} /> },
    { route: BaseRoutes.HomeFilter, component: <Dashboard /> }
  ];
  function getIndexRoute(type: EntityType): string {
    return getRoute(type, EditionMode.None);
  }
  function getCreateRoute(type: EntityType): string {
    return getRoute(type, EditionMode.Create);
  }
  function getDetailRoute(type: EntityType, id: string): string {
    return `${getRoute(type)}/${id}`;
  }
  const formatRoute = React.useCallback((route: string, mode: EditionMode = EditionMode.None): string => {
    switch (mode) {
      case EditionMode.Create:
        return `${route}/new`;
      case EditionMode.Detail:
        return `${route}/:id`;
      case EditionMode.Update:
        return `${route}/:id/:type`;
      default:
        return route;
    }
  }, []);

  const getConfig = React.useCallback((type: EntityType): IConfigProps | null => {
    switch (type) {
      case EntityType.EmbedReport:
        return embedReportsConfig;
      case EntityType.Market:
        return marketsConfig;
      case EntityType.EmbedReportFilter:
        return embedReportFiltersConfig;
      case EntityType.EmbedReportPage:
        return embedReportPagesConfig;
      case EntityType.EmbedTag:
        return embedTagsConfig;
      case EntityType.Section:
        return sectionsConfig;
      default:
        return null;
    }
  }, []);
  const getRoute = React.useCallback((type: EntityType, mode: EditionMode = EditionMode.None): string => {
    const config = getConfig(type);
    if (config) return formatRoute(config.path, mode);
    switch (type) {
      case EntityType.ReportCatalogSummary:
        return '/catalog';
      default:
        return '/';
    }
  }, []);
  function BuildEntityAppRoutes(): Array<IAppRouteProps> {
    const result: Array<IAppRouteProps> = [];
    for (const item in EntityType) {
      const indexView = getComponent(Number(item));
      if (indexView) result.push({ route: getRoute(Number(item)), component: <LazyLoader component={indexView} /> });
      const createView = getComponent(Number(item), EditionMode.Create);
      if (createView) result.push({ route: getRoute(Number(item), EditionMode.Create), component: <LazyLoader component={createView} /> });
      const detailView = getComponent(Number(item), EditionMode.Detail);
      if (detailView) result.push({ route: getRoute(Number(item), EditionMode.Detail), component: <LazyLoader component={detailView} /> });
    }
    return result;
  }
  function getComponent(
    type: EntityType,
    mode: EditionMode = EditionMode.None,
  ): React.LazyExoticComponent<() => React.ReactElement<any, string | React.JSXElementConstructor<any>>> | null {
    switch (mode) {
      case EditionMode.Create:
        if (type == EntityType.ReportCatalogSummary) return null;
        return React.lazy(() => import(`../pages/view/${EntityType[type]}CreatePage`));
      case EditionMode.Detail:
        if (type == EntityType.ReportCatalogSummary) return React.lazy(() => import(`../pages/catalog/ReportCatalogPage`));
        return React.lazy(() => import(`../pages/view/${EntityType[type]}ViewPage`));
      default:
        if (type == EntityType.ReportCatalogSummary) return null;
        return React.lazy(() => import(`../pages/index/${EntityType[type]}IndexPage`));
    }
  }

  const [AppRoutes] = React.useState<Array<IAppRouteProps>>([...BaseAppRoutes, ...BuildEntityAppRoutes()]);

  const [sidebar_menu] = React.useState<Array<IMenuItemProps>>([
    { id: 1, title: 'Home', icon: 'home', route: BaseRoutes.Home, flags: All, dashboard: false, admin: false },
    {
      id: 2,
      title: Resources.ReportCatalog,
      icon: 'database',
      route: getRoute(EntityType.ReportCatalogSummary),
      flags: MenuFlagEnum.All,
      dashboard: false,
      admin: false,
    },
    {
      id: 7,
      title: Resources.Reporting,
      icon: ['far', 'file-excel'],
      route: BaseRoutes.Reporting,
      flags: MenuFlagEnum.All,
      dashboard: true,
      admin: false,
    },
    {
      id: 8,
      title: Resources.SidePanel_Dashboard,
      icon: 'chalkboard-user',
      route: BaseRoutes.ControlPanel,
      flags: AllToOwner,
      dashboard: false,
      admin: false,
    },
    {
      id: 6,
      title: Resources.SidePanel_Settings,
      icon: 'cog',
      route: '#',
      flags: AllToOwner,
      dashboard: false,
      admin: true,
    },
    {
      id: 61,
      parentId: 6,
      title: Resources.Markets,
      icon: 'globe-europe',
      route: getRoute(EntityType.Market),
      flags: AllToOwner,
      description: Resources.Markets_Description,
      dashboard: true,
      admin: true,
    },
    {
      id: 66,
      parentId: 6,
      title: Resources.Sections,
      icon: 'tags',
      route: getRoute(EntityType.Section),
      flags: AllToOwner,
      description: Resources.Section_Description,
      dashboard: true,
      admin: true,
    },
    {
      id: 62,
      parentId: 6,
      title: Resources.EmbedReports,
      icon: 'tablet-alt',
      route: getRoute(EntityType.EmbedReport),
      flags: AllToOwner,
      description: Resources.EmbedReports_Description,
      dashboard: true,
      admin: true,
    },
    {
      id: 63,
      parentId: 6,
      title: Resources.EmbedReportPages,
      icon: 'folder-tree',
      route: getRoute(EntityType.EmbedReportPage),
      flags: AllToOwner,
      description: Resources.EmbedReportPages_Description,
      dashboard: true,
      admin: true,
    },
    {
      id: 64,
      parentId: 6,
      title: Resources.EmbedReportFilters,
      icon: 'sliders',
      route: getRoute(EntityType.EmbedReportFilter),
      flags: AllToOwner,
      description: Resources.EmbedReportFilters_Description,
      dashboard: true,
      admin: true,
    },
    {
      id: 65,
      parentId: 6,
      title: Resources.EmbedTags,
      icon: 'user-tag',
      route: getRoute(EntityType.EmbedTag),
      flags: AllToOwner,
      description: Resources.Section_Description,
      dashboard: false,
      admin: true,
    },
    {
      id: 67,
      parentId: 6,
      title: Resources.AdvancedOperations,
      icon: 'lock',
      route: BaseRoutes.Operations,
      flags: AllToOwner,
      description: Resources.EmbedReports_Description,
      dashboard: false,
      admin: true,
    },
    {
      id: 3,
      title: Resources.Help,
      icon: ['far', 'circle-question'],
      route: BaseRoutes.Help,
      flags: MenuFlagEnum.All,
      dashboard: false,
      admin: false,
    },
  ]);
  const getDashboardPanels = React.useCallback(() => {
    return currentProfile?.operationProfiles && currentProfile?.operationProfiles.length > 0
      ? sidebar_menu.filter((e) => e.dashboard === true).filter((e) => e.admin)
      : [];
  }, [currentProfile]);

  const getNavSidebarItems = React.useCallback(
    (parentId: Nullable<number> = undefined) => {
      return currentProfile?.admin
        ? sidebar_menu.filter((e) => e.parentId === parentId)
        : currentProfile?.marketAdmin
        ? sidebar_menu
            .filter((e) => e.parentId === parentId)
            .filter((e) => (e.flags & MenuFlagEnum.MarketAdmin) === MenuFlagEnum.MarketAdmin || e.flags === MenuFlagEnum.All)
        : currentProfile?.owner
        ? sidebar_menu
            .filter((e) => e.parentId === parentId)
            .filter((e) => (e.flags & MenuFlagEnum.Owner) === MenuFlagEnum.Owner || e.flags === MenuFlagEnum.All)
        : sidebar_menu.filter((e) => e.parentId === parentId).filter((e) => e.flags === MenuFlagEnum.All);
    },
    [currentProfile],
  );

  return {
    getIndexRoute,
    getCreateRoute,
    getDetailRoute,
    getConfig,
    getRoute,
    getComponent,
    formatRoute,
    getNavSidebarItems,
    getDashboardPanels,
    AppRoutes,
    BaseRoutes,
  };
};
export default useAppRoutes;
