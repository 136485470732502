import React from 'react';
import { formatDate, formatNumber } from '@progress/kendo-intl';
import { DataType } from '../../../config/Constants';
import { EnumType } from '../../../config/Enums';
import { IconTemplate, EnumTemplate, TemplateType } from './IconTemplate';
import { Col, Row } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IBoxProps {
  title?: string;
  field: string;
  value: any;
  type?: DataType;
  format?: string;
  route?: string;
  icon?: string | IconProp;
  enum?: EnumType;
  template?: TemplateType;
}

function DisplayBoxBase(props: IBoxProps): React.ReactElement | null {
  const formatValue = () => {
    if (!props.value) return '-';
    switch (props.type) {
      case DataType.Date:
      case DataType.DateTime:
        return props.format ? formatDate(new Date(props.value), props.format) : formatDate(new Date(props.value), 'd');
      case DataType.Integer:
        return props.format ? formatNumber(parseInt(props.value), props.format) : formatNumber(parseInt(props.value), 'n0');
      case DataType.Decimal:
        return props.format ? formatNumber(parseFloat(props.value), props.format) : formatNumber(parseFloat(props.value), 'n2');
      case DataType.Currency:
        return props.format ? formatNumber(parseFloat(props.value), props.format) : formatNumber(parseFloat(props.value), 'c2');
      default:
        return props.value ? props.value : '-';
    }
  };
  const hasValue = () => {
    if (props.type === DataType.List || props.template === TemplateType.List || props.template === TemplateType.Tags)
      return props.value && props.value.length > 0;
    return (props.value && props.value != '-') || props.type === DataType.Switch || props.type === DataType.CheckBox;
  };
  const BasicInput = () => {
    return (
      <>
        {props.type === DataType.Switch || props.type === DataType.CheckBox ? (
          <IconTemplate type={TemplateType.Boolean} value={props.value} withText={true} />
        ) : (
          <>
            {props.route ? (
              <IconTemplate type={TemplateType.Route} value={props.value} route={props.route} withText={true} />
            ) : (
              formatValue()
            )}
          </>
        )}
      </>
    );
  };
  const IconInput = () => {
    if (props.type === DataType.Switch || props.type === DataType.CheckBox)
      return <IconTemplate type={TemplateType.Boolean} value={props.value} withText={true} />;
    return <IconTemplate type={TemplateType.TextWithIcon} value={props.icon} withText={true} />;
  };
  if (!hasValue()) return null;
  return (
    <Row className="border-bottom py-2">
      <Col md={4} className="d-flex align-items-center" style={{ fontWeight: 300 }}>
        {props.title}
      </Col>
      <Col>
        {props.enum !== undefined && props.enum !== null ? (
          <EnumTemplate type={props.enum} value={props.value} withText={true} />
        ) : props.template !== undefined && props.template !== null ? (
          <IconTemplate type={props.template} value={props.value} route={props.route} icon={props.icon as IconProp} withText={true} />
        ) : props.icon ? (
          <IconInput />
        ) : (
          <BasicInput />
        )}
      </Col>
    </Row>
  );
}

function DisplayBoxPropsAreEqual(prev: IBoxProps, next: IBoxProps) {
  return (
    prev.field === next.field &&
    prev.value === next.value &&
    prev.title === next.title &&
    prev.type === next.type &&
    prev.enum === next.enum &&
    prev.template === next.template &&
    prev.icon === next.icon
  );
}
export const DisplayBox = React.memo(DisplayBoxBase, DisplayBoxPropsAreEqual);
