import { gql } from '@apollo/client';

export interface ISecurityProfileProps {
  user: ISecurityUserProps;
  anyAdmin: boolean;
  admin: boolean;
  marketAdmin: boolean;
  owner: boolean;
  groups: ISecurityGroupProps[];
  operationProfiles: IOperationProfileProps[];
}
export interface ISecurityGroupProps {
  groupId: string;
  groupName: string;
  admin: boolean;
}
export interface ISecurityUserProps {
  id: string;
  upn: string;
  userName: string;
}
export interface IOperationProfileProps {
  entityId: string;
  entity: string;
  role: string;
}

export const GET_SECURITY_PROFILE = {
  query: gql`
    query getCurrentProfile {
      security {
        currentProfile {
          anyAdmin
          admin
          marketAdmin
          owner
          user {
            id
            upn
            userName
          }
          operationProfiles {
            role
            entity
            entityId
          }
          groups {
            groupId
            groupName
          }
        }
      }
    }
  `,
  queryNode: 'currentProfile',
  rootNode: 'security',
};
