import { IMarketDisplayProps, IDisplayProps } from '../../services/GraphQLShared';
import { gql } from '@apollo/client';
import { EnumType, getIcon, IEnumProps, ProductTypeEnum, valueOf } from '../../../config/Enums';
import { Avatar, Card, CardBody } from '@progress/kendo-react-layout';
import { Col, Row } from 'react-bootstrap';
import { CommonResources } from '../../../config/CommonResources';
import { OnDemandIcon } from '../../helpers/IconLoader';
import { Tooltip } from '@progress/kendo-react-tooltip';
import lock from '../../../assets/images/lock.svg';

export interface IReportCatalogSummaryProps {
  id: string;
  reportName: string;
  code: string;
  type: string;
  embedArea: string;
  new: boolean;
  rLS: boolean;
  section: IDisplayProps;
  environment: string;
  description?: string;
  market: IMarketDisplayProps;
  formatTags: [IDisplayProps];
  display?: string | undefined;
  status: IEnumProps;
  totalPages: number;
  totalFilters: number;
  modifiedOn: Date;
  modifiedBy: string;
}
export const GET_DATACATALOG_SUMMARY = {
  query: gql`
    query GetReportCatalogSummary($search: String, $entityStatus: Int, $where: [FilterByParam], $orderBy: [OrderByParam]) {
      embedReports(search: $search, entityStatus: $entityStatus, where: $where, orderBy: $orderBy) {
        id
        reportName
        type
        display
        embedArea
        section {
          id
          display
          order
        }
        environment
        description
        rLS
        embedded
        groups
        new
        market {
          id
          display
          isoCode
        }
        formatTags {
          id
          display
          type
        }
        totalPages
        totalFilters
      }
    }
  `,
  queryNode: 'embedReports',
  textField: 'display',
  keyField: 'id',
};

export const RenderDashboard = (props) => {
  const isLink = props.item.type == ProductTypeEnum.Link;
  const e = props.item;
  if (e.access) {
    if (isLink && !e.embedded)
      return (
        <a href={e.embedArea} rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
          <ProductDashboardCard {...props} />
        </a>
      );
    return (
      <div onClick={() => props.navigateTo()}>
        <ProductDashboardCard {...props} />
      </div>
    );
  }
  return (
    <div>
      <ProductDashboardCard {...props} />
    </div>
  );
};

const ProductDashboardCard = (props) => {
  const e = props.item;
  const isLink = e.type == ProductTypeEnum.Link;
  const envChip = valueOf(e.environment, EnumType.EnvironmentType);
  const chipList =
    e.formatTags && e.formatTags.length > 0
      ? e.formatTags.map((x: any) =>
        Object.assign({
          display: x.display,
          id: x.id,
          icon: ` fa fa-${getIcon(x.type, EnumType.TagType)}`,
          color: '',
        }),
      )
      : [];
  chipList.push({ display: envChip.shortText, icon: `fa fa-${envChip.icon}`, id: envChip.code, color: envChip.color });
  return (
    <Card
      className="rounded border-none shadow-none country-cards"
      style={{
        width: 422,
        minHeight: 112,
        background: 'transparent',
        cursor: e.access ? 'pointer' : 'default',
      }}
    >
      <CardBody className="app-summary" style={{ background: 'transparent' }}>
        <Row>
          <Col md={3} className="country-avatar">
            {e.market.isoCode !== 'XX' ? (
              <Avatar rounded="full" type="image" size="large" border={false}>
                <img src={`/images/icons/${e.id}`} alt={e.market.isoCode} onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `/images/flags/1x1/${e.market.isoCode.toLowerCase()}.svg`;
                }} />
              </Avatar>
            ) : (
              <Avatar rounded="full" type="image" size="large">
                <img src={`/images/icons/${e.id}`} alt={e.market.isoCode} onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/images/flags/1x1/global.svg";
                }} />
              </Avatar>
            )}
          </Col>
          <Col className="d-flex align-items-center ps-1">
            <div>
              <Tooltip openDelay={100} position="right">
                <h4 className="mb-0 report-header" title={e.reportName}>
                  {e.reportName}
                </h4>
              </Tooltip>
            </div>
            {e.new && (
              <div className="new-icon-container mb-1 ms-2">
                <span className="new-icon-label">New</span>
              </div>
            )}
            {!e.access && <img src={lock} alt={CommonResources.NonAccessibleCard} className="mb-1 ms-2" style={{ width: '20px', height: '20px' }} />}
            {isLink && !e.embedded && (
              <OnDemandIcon
                icon={"arrow-up-right-from-square"}
                size="sm"
                className="ms-2 external-link"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={2}></Col>
          <Col className="ps-1">
            <Tooltip openDelay={100} position="right">
              <span className="report-desc" title={e.description}>
                {e.description}
              </span>
            </Tooltip>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
