import { IconDefinition, IconProp, library, findIconDefinition, IconLookup, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
const loadedIcons: Array<IconDefinition> = [];

const getIconLookup = (icon: IconProp): IconLookup => {
  if (typeof icon == 'string')
    return {
      prefix: 'fas',
      iconName: icon as IconName,
    };
  const value = icon as [IconPrefix, IconName];
  return {
    prefix: value[0],
    iconName: value[1],
  };
};
const loadIcon = (iconLoaded: IconProp) => {
  const iconLookup = getIconLookup(iconLoaded);
  const iconDefinition = findIconDefinition(iconLookup);
  try {
    if (!loadedIcons.find((x) => x.iconName === iconDefinition.iconName && x.prefix === iconDefinition.prefix)) {
      library.add(iconDefinition);
      loadedIcons.push(iconDefinition);
    }
  } catch {
    console.error(`Error loading icon ${iconLookup.prefix}-${iconLookup.iconName}`);
  }
};
export const OnDemandIcon = (props: FontAwesomeIconProps) => {
  loadIcon(props.icon);
  return <FontAwesomeIcon {...props} />;
};
