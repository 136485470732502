import React from 'react';
import { ISignalRMessageProps, SignalRMessageType } from '../../../services/SignalRHelper';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

export interface INotificationPopupProps {
  message: ISignalRMessageProps;
}
const NotificationPopup = (props: INotificationPopupProps): React.ReactElement | null => {
  const [state, setState] = React.useState(true);
  React.useEffect(() => {
    if (props.message) setState(true);
    const timeId = setTimeout(() => {
      setState(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [props.message]);

  if (!state) {
    return null;
  }
  return (
    <NotificationGroup
      style={{
        right: 0,
        bottom: 0,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
        zIndex: 10000,
      }}
    >
      {props.message.type === SignalRMessageType.Success ? (
        <Notification type={{ style: 'success', icon: true }} closable={true} onClose={() => setState(false)}>
          {props.message.title}
        </Notification>
      ) : props.message.type === SignalRMessageType.Error ? (
        <Notification type={{ style: 'error', icon: true }} closable={true} onClose={() => setState(false)}>
          {props.message.title}
        </Notification>
      ) : props.message.type === SignalRMessageType.Warning ? (
        <Notification type={{ style: 'warning', icon: true }} closable={true} onClose={() => setState(false)}>
          {props.message.title}
        </Notification>
      ) : (
        <Notification type={{ style: 'info', icon: true }} closable={true} onClose={() => setState(false)}>
          {props.message.title}
        </Notification>
      )}
    </NotificationGroup>
  );
};

export default NotificationPopup;
