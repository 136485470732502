import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { RootState } from './reducer/rootReducer';

const initialState = {};
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cbstate');
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return initialState;
  }
};

const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cbstate', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};
const persisted_state = loadState();

const store = createStore(rootReducer, persisted_state, applyMiddleware(thunk));
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
