import { IMessageDataProps } from '../../components/layout/elements/Message';
import { IMarketDisplayProps } from '../../components/services/GraphQLShared';
import { orderBy } from '@progress/kendo-data-query';
import * as navActions from '../actions/navActions';
import { loadStorage } from '../../components/helpers/Utils';
export type ThemeType = 'light' | 'dark';
export interface NavigationState {
  sidebarOpen: boolean;
  notificationOpen: boolean;
  settingsOpen: boolean;
  market?: IMarketDisplayProps | undefined;
  theme: ThemeType;
  landingPage: string;
  notifications: IMessageDataProps[];
  selectedReportTags: string[];
  showAllReports: boolean;
}
const initialState: NavigationState = {
  sidebarOpen: false,
  notificationOpen: false,
  settingsOpen: false,
  market: loadStorage('currentMarket', undefined),
  theme: (localStorage.getItem('currentTheme') as ThemeType) ?? 'light',
  landingPage: localStorage.getItem('currentLandingPage') ?? 'DEFAULT',
  notifications: [],
  selectedReportTags: JSON.parse(localStorage.getItem('selectedReportTags') || '[]'),
  showAllReports: false
};
export function navReducer(state = initialState, action: navActions.NavigationActionTypes): NavigationState {
  switch (action.type) {
    case navActions.CHANGE_MARKET:
      localStorage.setItem('currentMarket', JSON.stringify(action.value));
      return { ...state, market: action.value };

    case navActions.REPORTS_TAG_SELECTION:
      localStorage.setItem('selectedReportTags', JSON.stringify(action.ids));
      return { ...state, selectedReportTags: action.ids };

    case navActions.TOGGLE_SHOW_ACCESSIBLE_REPORTS:
      const showAllReports = state.showAllReports;
      return { ...state, showAllReports: !showAllReports };

    case navActions.CHANGE_THEME:
      localStorage.setItem('currentTheme', action.value);
      return { ...state, theme: action.value ?? 'light' };

    case navActions.CHANGE_LANDING_PAGE:
      localStorage.setItem('currentLandingPage', action.value);
      return { ...state, landingPage: action.value ?? 'DEFAULT' };

    case navActions.TOGGLE_SIDEBAR:
      const sideOpen = state.sidebarOpen;
      return { ...state, sidebarOpen: !sideOpen };

    case navActions.CLOSE_SIDEBAR:
      return { ...state, sidebarOpen: false };

    case navActions.TOGGLE_NOTIFICATIONS:
      const notificationOpen = state.notificationOpen;
      return { ...state, notificationOpen: !notificationOpen };

    case navActions.CLOSE_NOTIFICATIONS:
      return { ...state, notificationOpen: false };

    case navActions.ADD_NOTIFICATION:
      const newNotification: IMessageDataProps = {
        id: action.data.id,
        type: action.data.type,
        status: action.data.status,
        dateOccurred: action.data.dateOccurred,
        message: action.data.message,
        progress: action.data.progress,
        title: action.data.title,
        dismiss: false,
      };
      const added_notifications = state.notifications.filter((a) => a.id !== action.data.id).concat(newNotification);
      const added_result = orderBy(added_notifications, [{ field: 'dateOccurred', dir: 'desc' }]);
      return { ...state, notifications: added_result };

    case navActions.DISMISS_NOTIFICATION:
      const update_notifications = state.notifications.filter((a) => a.id !== action.id);
      const update_result = orderBy(update_notifications, [{ field: 'dateOccurred', dir: 'desc' }]);
      return { ...state, notifications: update_result };

    case navActions.PURGE_NOTIFICATIONS:
      return { ...state, notifications: [] };

    case navActions.TOGGLE_SETTINGS:
      const settingsOpen = state.settingsOpen;
      return { ...state, settingsOpen: !settingsOpen };

    case navActions.CLOSE_SETTINGS:
      return { ...state, settingsOpen: false };
    default:
      return state;
  }
}
export default navReducer;
