import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { apiConfig } from '../../config/Config';

export interface IJumboBoxProps {
  styleName?: string;
  children: React.ReactNode;
}
const JumboBox = (props: IJumboBoxProps): React.ReactElement => {
  return (
    <div className={props.styleName}>
      <Container>
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
};
export default JumboBox;

export interface IJumboImageBoxProps extends IJumboBoxProps {
  imageSource: string;
}

export const JumboImageBox = (props: IJumboBoxProps): React.ReactElement => {
  return (
    <div className={`p-5 mb-2`}>
      <Container className={`welcome-jumbo ${props.styleName}`}>
        <Row>
          <Col md={3}>
            <img src={`${apiConfig.storageEndpoint}misc/eye.png`} style={{ height: '10rem' }} />
          </Col>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
};
