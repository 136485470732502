import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo_white from '../../assets/images/logo_white.png';
import { CommonResources } from '../../config/CommonResources';

const Footer = (): React.ReactElement => {
  return (
    <footer className="bg-darkblue">
      <Container>
        <Row>
          <Col className="d-flex">
            <img src={logo_white} className="me-2" alt={CommonResources.PoweredByGroupM} style={{ height: '1rem', width: 'auto' }} />
            <p>
              © 2023 All rights reserved. <b>WPP</b> - <i>Leaders in Advertising, Marketing & Media</i>. Developed by{' '}
              <b style={{ color: '#328fff' }}>Global Investment Systems Team</b>.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
