import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HubConnection } from '@microsoft/signalr';
import { CLOSE_NOTIFICATIONS, ADD_NOTIFICATION, TOGGLE_NOTIFICATIONS } from '../../store/actions/navActions';
import Message from './elements/Message';
import NotificationPopup from './elements/NotificationPopup';
import { RootState } from '../../store/reducer/rootReducer';
import { CommonResources } from '../../config/CommonResources';
import cn from 'classnames';
import { Button } from '@progress/kendo-react-buttons';
import { xIcon } from "@progress/kendo-svg-icons";
import { IUserInfoProps } from '../services/GraphQLShared';
import { ISignalRMessageProps, setupSignalRConnection } from '../../services/SignalRHelper';

export type INotificationsProps = {
  isAuthenticated: boolean;
  user: IUserInfoProps;
  getAccessToken: (scopes: string[]) => Promise<string>;
};
const Notifications = (props: INotificationsProps) => {
  const selectNotificationOpen = (state: RootState) => state.nav.notificationOpen;
  const selectNotifications = (state: RootState) => state.nav.notifications;
  const notificationOpen = useSelector(selectNotificationOpen);
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  const [, setConnection] = useState<HubConnection | null>(null);
  const [newMessage, setNewMessage] = useState<ISignalRMessageProps | undefined>(undefined);
  React.useEffect(() => {
    const closeNotifications = (e: any) => {
      if (e.code === 'Escape' && notificationOpen) dispatch({ type: CLOSE_NOTIFICATIONS });
    };
    document.addEventListener('keydown', closeNotifications);
    return () => document.removeEventListener('keydown', closeNotifications);
  }, [notificationOpen]);

  useEffect(() => {
    if (props.isAuthenticated) {
      const setupEventsHub = setupSignalRConnection(
        'broadcastMessage',
        props.user,
        (message: ISignalRMessageProps) => {
          dispatch({ type: ADD_NOTIFICATION, data: message });
          setNewMessage(message);
        },
        props.getAccessToken,
      );
      setConnection(setupEventsHub);
    }
  }, []);

  React.useEffect(() => {
    const body = document.querySelector('body');
    const removeOverflow = () => body?.classList.remove('body-overflow');
    if (notificationOpen) {
      body?.classList.add('body-overflow');
    } else {
      removeOverflow();
    }
    return removeOverflow;
  }, [notificationOpen]);

  const onClickClose = () => dispatch({ type: TOGGLE_NOTIFICATIONS });
  const notificationsClass = cn('dialog mobile shadow', { open: notificationOpen });

  return (
    <>
      <aside className={notificationsClass} style={{ overflowY: 'auto', overflowX: 'hidden', height: 'initial' }}>
      <div className="dialog-header large d-flex"
          style={{
            justifyContent: 'space-between',
          }}>
          <h4 className="title">{CommonResources.Notifications}</h4>
          <Button type="button" fillMode={'flat'} svgIcon={xIcon} onClick={onClickClose} />
        </div>
        <div className="dialog-content">
          <ul style={{ width: '100%', listStyleType: 'none', margin: 0, padding: 0 }}>
            {notifications && notifications.map((e, i) => <Message data={e} key={i} />)}
          </ul>
        </div>
      </aside>
      {newMessage && <NotificationPopup message={newMessage} />}
    </>
  );
};
export default Notifications;
