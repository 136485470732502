import { ButtonGroup, Button, ToolbarSpacer, ToolbarItem } from '@progress/kendo-react-buttons';
import { searchIcon, tableIcon, cellsMergeIcon, plusIcon } from "@progress/kendo-svg-icons";
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { arrowRotateCwIcon } from "@progress/kendo-svg-icons";
import { CommonResources } from '../../../config/CommonResources';
import { Colors } from '../../../config/Constants';
import cn from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Avatar } from '@progress/kendo-react-layout';
import { TwoLetterAcronym } from '../../helpers/Utils';
import React from 'react';
import { OnDemandIcon } from '../../helpers/IconLoader';

export interface IStatusBoxProps {
  showActive: boolean;
  showDisable: boolean;
  showInactive: boolean;
  handleShowActive: () => void;
  handleShowDisable: () => void;
  handleShowInactive: () => void;
}

export function StatusBox(props: IStatusBoxProps): React.ReactElement {
  return (
    <ButtonGroup>
      <Button
        title={CommonResources.Button_ShowActives}
        className="k-icon-button"
        fillMode={'flat'}
        size={'small'}
        togglable={true}
        onClick={props.handleShowActive}
        selected={props.showActive}
      >
        <OnDemandIcon icon="circle" color={Colors.SuccessColor} className="m-0" />
      </Button>
      <Button
        title={CommonResources.Button_ShowDisable}
        className="k-icon-button"
        fillMode={'flat'}
        size={'small'}
        togglable={true}
        onClick={props.handleShowDisable}
        selected={props.showDisable}
      >
        <OnDemandIcon icon="circle" color={Colors.WarningColor} className="m-0" />
      </Button>
      <Button
        title={CommonResources.Button_ShowInactives}
        className="k-icon-button"
        fillMode={'flat'}
        size={'small'}
        togglable={true}
        onClick={props.handleShowInactive}
        selected={props.showInactive}
      >
        <OnDemandIcon icon="circle" color={Colors.ErrorColor} className="m-0" />
      </Button>
    </ButtonGroup>
  );
}
export interface ISearchBoxProps {
  search: string | undefined;
  className?: string;
  handleSearchChange?: (e: InputChangeEvent) => void;
  handleSearchClick?: () => void;
}
export function SearchBox(props: ISearchBoxProps): React.ReactElement {
  return (
    <div className={cn(props.className, { 'd-flex': !props.handleSearchClick }, 'search-input-field')}>
      {!props.handleSearchClick && (
        <OnDemandIcon icon={'search'} className="text-muted" style={{ position: 'relative', right: -13, top: 10 }} />
      )}
      <Input
        placeholder={CommonResources.Search}
        style={{ background: 'transparent', border: 'none' }}
        onChange={props.handleSearchChange}
        value={props.search}
      />
      {props.handleSearchClick && (
        <Button
          svgIcon={searchIcon}
          size={'small'}
          fillMode={'flat'}
          style={{ position: 'absolute', right: 5 }}
          onClick={props.handleSearchClick}
        />
      )}
    </div>
  );
}
export interface IBasicToolbarProps extends IStatusBoxProps, ISearchBoxProps {
  handleNew?: () => void;
  handleReload?: () => void;
  handleViewMode?: () => void;
  viewMode?: 'list' | 'grid' | undefined;
  includeSpacer: boolean;
  title?: string;
  subTitle?: string;
}
export function BasicToolbar(props: IBasicToolbarProps): React.ReactElement {
  const ViewModeButton = () => {
    if (!props.viewMode) return null;
    return (
      <Button
        svgIcon={props.viewMode == 'list' ? tableIcon : cellsMergeIcon}
        size={'small'}
        togglable={true}
        title={CommonResources.Button_ViewMode}
        onClick={props.handleViewMode}
      />
    );
  };
  const NewButton = () => {
    if (props.title)
      return (
        <>
          {props.includeSpacer && <ToolbarSpacer className="d-none d-xs-none d-sm-block" />}
          <ToolbarItem>
            <ButtonGroup>
              {props.handleNew && <Button svgIcon={plusIcon} size={'small'} title={CommonResources.Button_New} onClick={props.handleNew} />}
              <ViewModeButton />
              <Button svgIcon={arrowRotateCwIcon} size={'small'} title={CommonResources.Button_Reload} onClick={props.handleReload} />
            </ButtonGroup>
          </ToolbarItem>
        </>
      );
    return (
      <>
        <ToolbarItem>
          <ButtonGroup>
            {props.handleNew && <Button svgIcon={plusIcon} size={'small'} title={CommonResources.Button_New} onClick={props.handleNew} />}
            <ViewModeButton />
            <Button svgIcon={arrowRotateCwIcon} size={'small'} title={CommonResources.Button_Reload} onClick={props.handleReload} />
          </ButtonGroup>
        </ToolbarItem>
        {props.includeSpacer && <ToolbarSpacer className="d-none d-xs-none d-sm-block" />}
      </>
    );
  };
  const Title = () => {
    if (!props.title) return null;
    if (!props.subTitle)
      return (
        <ToolbarItem className="d-none d-xs-none d-sm-block">
          <h4 className="mb-0">{props.title}</h4>
        </ToolbarItem>
      );
    return (
      <ToolbarItem className="d-none d-xs-none d-sm-block">
        <h4 className="mb-0">{props.title}</h4>
        <p className="mb-0">{props.subTitle}</p>
      </ToolbarItem>
    );
  };
  return (
    <>
      <Title />
      <NewButton />
      <ToolbarItem>
        <StatusBox
          showActive={props.showActive}
          showDisable={props.showDisable}
          showInactive={props.showInactive}
          handleShowActive={props.handleShowActive}
          handleShowDisable={props.handleShowDisable}
          handleShowInactive={props.handleShowInactive}
        />
      </ToolbarItem>
      <ToolbarItem>
        <SearchBox search={props.search} handleSearchChange={props.handleSearchChange} handleSearchClick={props.handleSearchClick} />
      </ToolbarItem>
    </>
  );
}

export interface IAvatarBoxProps {
  image?: string;
  isoCode?: string;
  text: string | number;
  icon?: IconProp;
  id?: string;
  size?: null | 'small' | 'medium' | 'large';
}
function AvatarBoxTemplate(props: IAvatarBoxProps): React.ReactElement {
  if (props.id && props.isoCode) {
    return (
      <Avatar themeColor={'tertiary'} rounded="full" type="image" size={props.size}>
        <img src={`/images/icons/${props.id}`} alt={props.text as string} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = props.isoCode === 'XX'
            ? `/images/flags/1x1/global.svg`
            : `/images/flags/1x1/${props.isoCode?.toLowerCase()}.svg`;
        }} />
      </Avatar>
    );
  }
  if (props.isoCode) {
    return (
      <Avatar themeColor={'tertiary'} rounded="full" type="image" size={props.size}>
        <img
          src={
            props.isoCode === 'XX'
              ? `/images/flags/1x1/global.svg`
              : `/images/flags/1x1/${props.isoCode.toLowerCase()}.svg`
          }
          alt={props.text as string}
        />
      </Avatar>
    );
  }
  if (props.image)
    return (
      <Avatar themeColor={'tertiary'} rounded="full" type="image" size={props.size}>
        <img src={`data:image/jpeg;base64,${props.image}`} alt={props.text as string} />
      </Avatar>
    );
  if (props.icon)
    return (
      <Avatar themeColor={'tertiary'} rounded="full" type="icon" size={props.size}>
        <OnDemandIcon icon={props.icon} size={'lg'} />
      </Avatar>
    );

  return (
    <Avatar themeColor={'tertiary'} rounded="full" type="text" size={props.size}>
      {typeof props.text != 'number' ? TwoLetterAcronym(props.text as string) : props.text}
    </Avatar>
  );
}
function AvatarBoxPropsAreEqual(prev: IAvatarBoxProps, next: IAvatarBoxProps) {
  return (
    prev.image === next.image &&
    prev.isoCode === next.isoCode &&
    prev.text === next.text &&
    prev.size === next.size &&
    prev.icon === next.icon
  );
}
export const AvatarBox = React.memo(AvatarBoxTemplate, AvatarBoxPropsAreEqual);
