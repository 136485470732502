import { IUserInfoProps } from '../components/services/GraphQLShared';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const graph = require('@microsoft/microsoft-graph-client');
function getAuthenticatedClient(accessToken: string) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string): Promise<IUserInfoProps> {
  const client = getAuthenticatedClient(accessToken);
  const user = await client
    .api('/me')
    .select('displayName,mail,userPrincipalName, jobtitle, givenName, officeLocation, mobilePhone, id, preferredLanguage')
    .get();
  user.avatar = await getUserPhoto(accessToken, user.id);
  return user;
}

export async function getUserPhoto(accessToken: string, upn: string): Promise<string> {
  try {
    const client = getAuthenticatedClient(accessToken);
    const blob = await client.api(`/users/${upn}/photo/$value`).get();
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  } catch (e) {
    return '';
  }
}

export function errorHandler(err: any): Error {
  let error = err;
  if (err.response && err.response.data.hasOwnProperty('message')) error = err.response.data;
  else if (!err.hasOwnProperty('message')) error = err.toJSON();

  return new Error(error.message);
}
