import { IDisplayProps } from '../services/GraphQLShared';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const guidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
export const emailValidator = (value: string): string =>
  !value || emailRegex.test(value) || (value && value.startsWith('#TEMP#')) ? '' : 'Email is not valid format.';
export const emailRequiredValidator = (value: string): string =>
  !value ? 'Email field is required.' : emailRegex.test(value) || (value && value.startsWith('#TEMP#')) ? '' : 'Email is not valid format.';
export const nameValidator = (value: string): string =>
  !value ? 'Name is required' : value.length < 3 ? 'Name should be at least 3 characters long.' : '';
export const codeValidator = (value: string): string => (value.length < 2 ? 'Code should be at least 2 characters long.' : '');
export const isoValidator = (value: string): string =>
  !value ? 'ISO Code is required' : value.length !== 2 ? 'ISO Code should be 2 characters long.' : '';
export const cultureValidator = (value: string): string =>
  !value ? 'ISO Culture Code is required' : value.length !== 5 ? 'ISO Culture Code should be 5 characters long with format XX-YY' : '';
export const guidValidator = (value: string): string => (guidRegex.test(value) ? '' : 'The value is not valid GUID format.');
export const guidListValidator = (value: string): string => {
  if (value.indexOf(';') > 0) {
    const guidList = value.split(';');
    let isValid = true;
    guidList.forEach((e) => {
      if (!guidRegex.test(e.trim())) {
        isValid = false;
      }
    });
    return isValid ? '' : 'The value is not valid list of GUIDs.';
  } else return guidRegex.test(value) ? '' : 'The value is not valid list of GUIDs.';
};

export const guidOptionalValidator = (value: string): string =>
  value && guidRegex.test(value) ? '' : 'The value is not valid GUID format.';

export const userNameValidator = (value: string): string =>
  !value ? 'User Name is required' : value.length < 5 ? 'User name should be at least 3 characters long.' : '';
export const phoneValidator = (value: string): string =>
  !value ? 'Phone number is required.' : phoneRegex.test(value) ? '' : 'Not a valid phone number.';
export const dateValidator = (value: string): string => (value ? '' : 'Date is required');
export const colorValidator = (value: string): string => (value ? '' : 'Color is required.');
export const requiredListValidator = (value: string): string => (value && value.length > 0 ? '' : 'Error: This field is required.');
export const requiredValidator = (value: string): string => (value ? '' : 'Error: This field is required.');
export const checkboxValidator = (value: boolean): string => (value ? '' : '');
export const radioGroupValidator = (value: string) => (!value ? 'Value is required' : '');
export const idDisplayValidator = (value: IDisplayProps): string =>
  !value || !value.id ? 'This field is required, please select a value.' : '';
export const idDisplayListValidator = (value: [any]): string =>
  !value || value.length < 1 ? 'This field is required, please select at least one value.' : '';
export const enumValidator = (value: any): string => (!value || !value.code ? 'This field is required, please select a value.' : '');
export const enumListValidator = (value: [any]): string =>
  !value || value.length < 1 ? 'This field is required, please select at least one value.' : '';
export const orderValidator = (value: number): string =>
  value < -1 ? 'Min order is -1'  : '';