import axios from 'axios';
import fileDownload from 'js-file-download';
import { API_ENDPOINTS, API_REPORTS } from '../config/Config';
import { formatDate } from '@progress/kendo-intl';
import  { ReportTypeEnum } from '../config/Enums';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handler(err: any): Error {
  let error = err;

  if( err.response.data instanceof ArrayBuffer ){
    try {
      error = JSON.parse(new TextDecoder().decode(err.response.data  as ArrayBuffer));
    } catch (e) {
      error = {};
    }
  }

  if (err.response && err.response.data.hasOwnProperty('message')) error = err.response.data;
  else if (error && error.hasOwnProperty('detail')) error.message = error.detail;
  else if (!err.hasOwnProperty('message')) error = err.toJSON();

  return new Error(error.message);
}
async function getHeadersAsync(accessToken: string) {
  return { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } };
}
export async function getSystemInfoDetails(accessToken: string): Promise<any> {
  try {
    const options = await getHeadersAsync(accessToken);
    const url = `${API_ENDPOINTS.GET_SYSTEM_INFO}`;
    const res = await axios.get(url, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export interface IReportParameters {
  type: number;
  status?: number;
  marketIds?: string[];
  sectionIds?: string[];
  ids?: string[];
  startDate?: Date;
  endDate?: Date;
}
const getFileName = (parameters: IReportParameters) => {
  switch (parameters.type) {
    case API_REPORTS.ProductCatalog:
      return `GISPortal_ProductCatalog_${formatDate(new Date(), 'yyyyMMdd')}.xlsx`;
    case API_REPORTS.ProductSecurity:
      return `GISPortal_ProductSecurity_${formatDate(new Date(), 'yyyyMMdd')}.xlsx`;
    default:
      return `GISPortal_ProductTrail_${formatDate(new Date(), 'yyyyMMdd')}.xlsx`;
  }
};
export async function downloadReport(parameters: IReportParameters): Promise<void> {
  try {
    const url = `${API_ENDPOINTS.GET_REPORT}`;
    const res = await axios.post(url, parameters, { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' });
    fileDownload(res.data, getFileName(parameters));
  } catch (e) {
    throw handler(e);
  }
}
export async function downloadExportReport(entity: string): Promise<void> {
  try {
    const url = `${API_ENDPOINTS.GET_REPORT}`;
    const parameters : IReportParameters = {type: ReportTypeEnum[entity]};
    const res = await axios.post(url, parameters, { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' });
    fileDownload(res.data, `GISPortal_${entity.toUpperCase()}_${formatDate(new Date(), 'yyyyMMdd')}.xlsx`);
  } catch (e) {
    throw handler(e);
  }
}
