import React from 'react';

function Welcome(): React.ReactElement {
  return (
    <div className="app__content">
    </div>
  );
}

export default React.memo(Welcome);
