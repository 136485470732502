import { IMarketDisplayProps } from '../../components/services/GraphQLShared';
import { ISignalRMessageProps } from '../../services/SignalRHelper';
import { ThemeType } from '../reducer/navReducer';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';
export const CLOSE_SETTINGS = 'CLOSE_SETTINGS';
export const CHANGE_MARKET = 'CHANGE_MARKET';
export const CHANGE_APPPLATFORM = 'CHANGE_APPPLATFORM';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_LANDING_PAGE = 'CHANGE_LANDING_PAGE';
export const EVAL_NAVIGATION_HISTORY = 'EVAL_NAVIGATION_HISTORY';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';
export const PURGE_NOTIFICATIONS = 'PURGE_NOTIFICATIONS';
export const LOAD_SYSTEM_INFO = 'LOAD_SYSTEM_INFO';
export const ERROR_SYSTEM_INFO = 'GET_SYSTEM_INFO';
export const REPORTS_TAG_SELECTION = 'REPORTS_TAG_SELECTION';
export const TOGGLE_SHOW_ACCESSIBLE_REPORTS = 'TOGGLE_SHOW_ACCESSIBLE_REPORTS';

interface ChangeMarketAction {
  type: typeof CHANGE_MARKET;
  value: IMarketDisplayProps;
}
interface ReportsTagSelectionAction {
  type: typeof REPORTS_TAG_SELECTION;
  ids: string[];
}
interface ToggleAccessibleReportsAction {
  type: typeof TOGGLE_SHOW_ACCESSIBLE_REPORTS;
}
interface ChangeThemeAction {
  type: typeof CHANGE_THEME;
  value: ThemeType;
}
interface ChangeLandingPageAction {
  type: typeof CHANGE_LANDING_PAGE;
  value: string;
}
interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR;
}
interface ToggleNotificationAction {
  type: typeof TOGGLE_NOTIFICATIONS;
}
interface ToggleSettingsAction {
  type: typeof TOGGLE_SETTINGS;
}
interface CloseSettingsAction {
  type: typeof CLOSE_SETTINGS;
}
interface CloseSidebarAction {
  type: typeof CLOSE_SIDEBAR;
}
interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION;
  id: string;
}
interface AddNotification {
  type: typeof ADD_NOTIFICATION;
  data: ISignalRMessageProps;
}
interface CloseNotifications {
  type: typeof CLOSE_NOTIFICATIONS;
}
interface PurgueNotificationsAction {
  type: typeof PURGE_NOTIFICATIONS;
}
interface EvalNavigationAction {
  type: typeof EVAL_NAVIGATION_HISTORY;
  location: string;
}
export type NavigationActionTypes =
  | ChangeMarketAction
  | ChangeThemeAction
  | ChangeLandingPageAction
  | ToggleSidebarAction
  | CloseSidebarAction
  | ToggleNotificationAction
  | ToggleSettingsAction
  | CloseSettingsAction
  | DismissNotificationAction
  | AddNotification
  | CloseNotifications
  | PurgueNotificationsAction
  | EvalNavigationAction
  | ReportsTagSelectionAction
  | ToggleAccessibleReportsAction;

export function changeMarket(value: IMarketDisplayProps): NavigationActionTypes {
  return { type: CHANGE_MARKET, value: value };
}
export function changeReportsTagSelection(ids: string[]): NavigationActionTypes {
  return { type: REPORTS_TAG_SELECTION, ids };
}
export function toggleAccessibleReports(): NavigationActionTypes {
  return { type: TOGGLE_SHOW_ACCESSIBLE_REPORTS };
}
export function changeTheme(value: ThemeType): NavigationActionTypes {
  return { type: CHANGE_THEME, value: value };
}
export function changeLandingPage(value: string): NavigationActionTypes {
  return { type: CHANGE_LANDING_PAGE, value: value };
}
export function toggleNotifications(): NavigationActionTypes {
  return { type: TOGGLE_NOTIFICATIONS };
}
export function toggleSettings(): NavigationActionTypes {
  return { type: TOGGLE_SETTINGS };
}
export function closeSettings(): NavigationActionTypes {
  return { type: CLOSE_SETTINGS };
}
export function toggleSidebar(): NavigationActionTypes {
  return { type: TOGGLE_SIDEBAR };
}
export function closeSidebar(): NavigationActionTypes {
  return { type: CLOSE_SIDEBAR };
}
export function dismissNotification(id: string): NavigationActionTypes {
  return { type: DISMISS_NOTIFICATION, id: id };
}
export function addNotification(data: ISignalRMessageProps): NavigationActionTypes {
  return { type: ADD_NOTIFICATION, data: data };
}
export function closeNotifications(): NavigationActionTypes {
  return { type: CLOSE_NOTIFICATIONS };
}
export function purgueNotifications(): NavigationActionTypes {
  return { type: PURGE_NOTIFICATIONS };
}

export function evalNavigation(location: string): NavigationActionTypes {
  return { type: EVAL_NAVIGATION_HISTORY, location: location };
}
