import { apiConfig, UI_RELEASE_VERSION } from '../../config/Config';
import { ApolloClient, ApolloError, ApolloLink, from, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useSelector } from 'react-redux';
import { EditionFlagEnum, EditionFlags } from '../../config/Enums';
import { RootState } from '../../store/reducer/rootReducer';
import { EntityType } from '../services/GraphQLModel';
import { gql_Types } from '../services/GraphQLShared';
import axios from 'axios';
import { isDevelopment } from './Utils';
import { ViewType } from '../../config/Constants';

export function updateHeadersTokens(getAccessToken: (scopes: string[]) => Promise<string>) {
  getAccessToken(apiConfig.scopes).then((result) => {
    if (axios.defaults.headers) axios.defaults.headers.common['Authorization'] = `Bearer ${result}`;
  });
}
export function parseGraphQLError(message: string): string {
  if (message.startsWith('You are not authorized to run this query')) return message;
  const result = message.split('\r\n');
  if (result.length > 2) return result[1].replace('---> System.Exception:', '').trim();
  return message
    .replace('GraphQL.Server.Authorization.AspNetCore.AuthorizationError:', '')
    .replace('GraphQL.Server.', '')
    .replace('---> System.Exception:', '')
    .replace('App.Framework.Common.Exceptions.GraphQlException:', '')
    .replace(
      'App.Framework.Common.Exceptions.UnauthorizedException: Security error.',
      'You are not <b>authorized</b> to run this query. Please ensure you are a <u>GIS Access Manager active user</u> for the selected application or try to login again',
    )
    .replace('--->', '')
    .replace('GraphQL.Execution.UnhandledError:', '<u>Error</u>: ')
    .trim();
}
export function parseGraphQLErrors(data: ApolloError): string[] {
  if (data.graphQLErrors?.length > 0) return data.graphQLErrors?.map(({ message }) => parseGraphQLError(message));
  if (data.clientErrors?.length > 0) return data.clientErrors?.map(({ message }) => parseGraphQLError(message));
  return [data.message];
}
export const makeApolloClient = (authMiddleware: ApolloLink, getAccessToken: (scopes: string[]) => Promise<string>, logout: () => void) => {
  const httpLink = new HttpLink({ uri: apiConfig.graphqlEndPoint });
  const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (isDevelopment && networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    if (isDevelopment && graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}}, Extensions: ${JSON.stringify(extensions)}`,
        ),
      );
    }
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED':
          case 'ACCESS_DENIED':
          case 'FORBIDDEN':
          case 'AUTHORIZATION':
            return new Observable((observer) => {
              getAccessToken(apiConfig.scopes)
                .then((accessToken) => {
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }));
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                  logout();
                });
            });
        }
      }
    }
  });
  return new ApolloClient({
    link: from([authMiddleware, onErrorLink, httpLink]),
    cache: new InMemoryCache(),
    name: 'GISPortal',
    version: UI_RELEASE_VERSION,
    typeDefs: gql_Types,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};

export function useSecurityProfile(): any {
  const selectProfile = (state: RootState) => state.security.profile;
  const currentProfile = useSelector(selectProfile);
  return {
    profile: currentProfile,
  };
}

export function useSecurity(type: EntityType, view: ViewType, readOnly = false): any {
  const selectProfile = (state: RootState) => state.security.profile;
  const currentProfile = useSelector(selectProfile);

  const mapProfile = () => {
    if (readOnly) return EditionFlags.AllowView;
    if (!currentProfile?.anyAdmin) return EditionFlags.AllowView;
    switch (view) {
      case ViewType.Index:
        switch (type) {
          case EntityType.EmbedReportFilter:
            return currentProfile?.anyAdmin
              ? EditionFlagEnum.CanView | EditionFlagEnum.CanUpdate | EditionFlagEnum.CanDelete
              : EditionFlags.AllowView;
          default:
            return getIndex();
        }
      case ViewType.View:
        return getIndex();
      case ViewType.List:
        switch (type) {
          case EntityType.EmbedReport:
            return EditionFlags.AllowView;
          default:
            return getIndex();
        }
    }
    return EditionFlags.AllowView;
  };
  const getIndex = () => {
    switch (type) {
      case EntityType.Market:
        return currentProfile?.anyAdmin ? EditionFlags.AllowEdition : EditionFlags.AllowView;
      case EntityType.EmbedReport:
        return currentProfile?.anyAdmin ? EditionFlags.AllowEdition : EditionFlags.AllowView;
      case EntityType.EmbedReportPage:
        return currentProfile?.anyAdmin
          ? EditionFlagEnum.CanView | EditionFlagEnum.CanUpdate | EditionFlagEnum.CanDelete
          : EditionFlags.AllowView;
      case EntityType.EmbedReportFilter:
        return currentProfile?.anyAdmin ? EditionFlags.AllowEdition : EditionFlags.AllowView;
    }
    return EditionFlags.AllowEdition;
  };
  return {
    profile: currentProfile,
    flags: mapProfile(),
    canUpdate: canUpdate(mapProfile()),
    canDelete: canDelete(mapProfile()),
    canView: canView(mapProfile()),
    canCreate: canCreate(mapProfile()),
    canDuplicate: canDuplicate(mapProfile()),
  };
}

export function canUpdate(flags: EditionFlagEnum): boolean {
  return (flags & EditionFlagEnum.CanUpdate) === EditionFlagEnum.CanUpdate;
}
export function canDelete(flags: EditionFlagEnum): boolean {
  return (flags & EditionFlagEnum.CanDelete) === EditionFlagEnum.CanDelete;
}
export function canView(flags: EditionFlagEnum): boolean {
  return (flags & EditionFlagEnum.CanView) === EditionFlagEnum.CanView;
}
export function canCreate(flags: EditionFlagEnum): boolean {
  return (flags & EditionFlagEnum.CanCreate) === EditionFlagEnum.CanCreate;
}
export function canDuplicate(flags: EditionFlagEnum): boolean {
  return (flags & EditionFlagEnum.CanDuplicate) === EditionFlagEnum.CanDuplicate;
}
