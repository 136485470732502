import React, { useEffect } from 'react';
import { RootState } from '../../store/reducer/rootReducer';
import JumboBox from '../shared/JumboBox';
import { CommonResources, Resources } from '../../config/CommonResources';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { InputChangeEvent, Switch } from '@progress/kendo-react-inputs';
import { useSelector } from 'react-redux';
import { IMenuItemProps, IUserInfoProps } from '../services/GraphQLShared';
import WelcomeNoAuthorized from './WelcomeNoAuthorized';
import useAppRoutes from '../helpers/RouteHook';
import { useNavigate } from 'react-router-dom';
import { GET_DATACATALOG_SUMMARY, RenderDashboard } from '../pages/catalog/ReportCatalogSummary';
import { useQuery } from '@apollo/client';
import { GenericLoadingPanel } from './panels/Panels';
import { EntityStatusEnum, ProductTypeEnum } from '../../config/Enums';
import { process } from '@progress/kendo-data-query';
import { SearchBox } from './boxes/StatusBox';
import { OnDemandIcon } from '../helpers/IconLoader';
import useAppModel, { QueryTypeEnum } from '../helpers/ModelHook';
import { embedTagsConfig } from '../services/GraphQLTypes';
import { Nullable } from '../helpers/Utils';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { useDispatch } from 'react-redux';
import { changeReportsTagSelection, TOGGLE_SHOW_ACCESSIBLE_REPORTS } from '../../store/actions/navActions';

export const WelcomeHeader = (user: Nullable<IUserInfoProps> = null): React.ReactElement => {
  return (
    <>
      <span className="name">
        {CommonResources.Hello} <span>{user?.givenName ?? 'Stranger'}</span>
      </span>
      <span className="welcome-message">
        Welcome to the {Resources.ApplicationName}
      </span>
      <p
        dangerouslySetInnerHTML={{
          __html: user ? Resources.WelcomeMesaage : `${Resources.ApplicationIntro}${Resources.WelcomeMesaage}`,
        }}
      ></p>
    </>
  );
};

export const LoadCatalog = (loading, error, dataProducts, state, currentSecurity, navigate, accessibleProducts): React.ReactElement[] => {
  let selectedProducts = state.showAllReports ? dataProducts[GET_DATACATALOG_SUMMARY.queryNode] : accessibleProducts;
  if (state.selectedTags.length > 0) 
    selectedProducts = selectedProducts?.filter((e: any) =>
      e.formatTags.some((r: any) => state.selectedTags.indexOf(r.id) >= 0),
    );
  if (state.search) {
    const searchLower = state.search.toLowerCase();
    selectedProducts = selectedProducts.filter(
      (e: any) =>
        ~e.reportName.toLowerCase().indexOf(searchLower) ||
        ~e.section.display.toLowerCase().indexOf(searchLower) ||
        ~e.market.display.toLowerCase().indexOf(searchLower) ||
        e.formatTags.some((r: any) => ~r.display.toLowerCase().indexOf(searchLower)),
    );
  }
  const profiles = currentSecurity.profile.operationProfiles;
  const selectedProductsCopy: any[] = [];
  selectedProducts?.forEach((e) => selectedProductsCopy.push(Object.assign({}, e)));
  selectedProductsCopy?.forEach((e) => e.access = e.groups && profiles.some((r: any) => e.groups.toLowerCase().indexOf(r.entityId.toLowerCase()) > -1));
  if (!state.showAllReports) {
    if(accessibleProducts?.length <= 0) {
      return [<div className="d-flex justify-content-center panel-error-msg">{CommonResources.Message_NoAccessibleProduct}</div>];  
    }
  }
  const groupProducts = process(selectedProductsCopy, {
    group: [{ field: 'section.order',aggregates: [{ aggregate: "min", field: "section.display" }] }],
    sort: [{ field: 'access', dir: 'desc' }, { field: 'reportName', dir: 'asc' }],
  });
  return groupProducts.data.map((group: any, i: number) => (
    <PanelBarItem key={i} title={group.aggregates["section.display"].min} expanded={true} className="fw-bold">
      <div className="my-2 k-card-group" style={{ flexFlow: 'row wrap' }}>
        {group.items.map((e: any, j: any) => (
          <RenderDashboard
            key={j}
            item={e}
            navigateTo={() => navigate(e.type == ProductTypeEnum.Link ? `/embedded/${e.id}` : e.type == ProductTypeEnum.CrossTab ? `/crosstab/${e.id}` : `/reportviewer/${e.id}`)}
          />
        ))}
      </div>
    </PanelBarItem>
  ));
};

function Dashboard(): React.ReactElement {
  const navigate = useNavigate();
  const { getDashboardPanels } = useAppRoutes();
  const { buildQuery } = useAppModel();
  const GET_EMBED_TAG_LIST = buildQuery(embedTagsConfig, QueryTypeEnum.GetList);

  const selectSecurity = (state: RootState) => state.security;
  const currentSecurity = useSelector(selectSecurity);
  const [panels] = React.useState<IMenuItemProps[]>(getDashboardPanels());
  const [expanded, setExpanded] = React.useState(['.0', '.1', '.2', '.3', '.4', '.5']);
  const handleClick = (box: IMenuItemProps) => navigate(box.route);

  const { loading: loadingTags, data: tags } = useQuery(GET_EMBED_TAG_LIST.query, {
    variables: { entityStatus: EntityStatusEnum.Active },
  });
  const {
    loading,
    error,
    data: dataProducts,
  } = useQuery(GET_DATACATALOG_SUMMARY.query, { variables: { entityStatus: EntityStatusEnum.Active } });
  const data = loadingTags ? [] : tags[GET_EMBED_TAG_LIST.queryNode];
  const dispatch = useDispatch();
  const selectedReportTags = useSelector((state: RootState) => state.nav.selectedReportTags);
  const { tag } = useParams();
  if (tag) {
    data.filter(item => tag.toUpperCase() == item.name.toUpperCase()).forEach(function (value) {
      selectedReportTags.push(value.id);
    });
  }
  const selectedTags = data.filter(item => selectedReportTags?.includes(item.id));
  const showAllReportsNav = (state: RootState) => state.nav.showAllReports;
  const showAllReports = useSelector(showAllReportsNav);
  const [accessibleProducts, setAccessibleProducts] = React.useState<any[]>([]);
  const [state, setState] = React.useState<any>({
    selectedTags: [] as any,
    atlasTag: '',
    search: '',
    showAllReports: showAllReports
  });

  useEffect(() => {
    if (dataProducts && dataProducts[GET_DATACATALOG_SUMMARY.queryNode]) {
      const profiles = currentSecurity.profile?.operationProfiles;
      const productsCopy = dataProducts[GET_DATACATALOG_SUMMARY.queryNode].map((e: any) => {
        const hasAccess = e.groups && profiles?.some((r: any) => e.groups.toLowerCase().indexOf(r.entityId.toLowerCase()) > -1);
        return { ...e, access: hasAccess };
      });
      const accessibleProductsList = productsCopy.filter((e: any) => e.access);
      setAccessibleProducts(accessibleProductsList);
      setState({
        ...state,
        selectedTags: (selectedReportTags?.length ?? 0) > 0 ? selectedReportTags : [],
        showAllReports: (accessibleProductsList?.length ?? 0) === 0 ? true : showAllReports
      });
    }
  }, [selectedReportTags, dataProducts]);

  const handleMultiselectTagChange = (event) => {
    const ids = event.value.map(item => item.id);
    dispatch(changeReportsTagSelection(ids));
    setState({
      ...state,
      selectedTags: ids,
    });
  };

  const handleSwitchChange = (event) => {
    dispatch({ type: TOGGLE_SHOW_ACCESSIBLE_REPORTS })
    setState({
      ...state,
      showAllReports: event.value,
    });
  }

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  const renderPanels = (title: string, panels: IMenuItemProps[], styleName: string) => {
    return (
      <>
        <Row>
          <Col style={{ textAlign: 'center' }} className="mt-3 mb-2">
            <h4 className="mb-1 white-text">{title}</h4>
          </Col>
        </Row>
        <Row>
          {panels.map((box: IMenuItemProps, i: number) => (
            <Col key={i} className="p-2 with-pointer" onClick={() => handleClick(box)}>
              <Card className={`shadow-none text-center ${styleName}`}>
                <CardBody>
                  <OnDemandIcon className="white-text mb-3" icon={box.icon} size="3x" />
                  <CardTitle className="fr-white white-text">{box.title}</CardTitle>
                  <p className="d-none d-lg-block white-text admin-card-desc">
                    {box.description}
                  </p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  };
  const handleSearchChange = (e: InputChangeEvent) => setState({ ...state, search: e.value });
  return currentSecurity.isAuthorized ? (
    <div className="app__content">
      <div>
        <Container fluid className="welcome-jumbo mt-4 px-5">
          <Row className="mb-3">
            <Col className="welcome-header">
              {WelcomeHeader(currentSecurity.userInfo)}
              {/* {currentSecurity.systemInfo?.supportEmail && (
                <ButtonGroup>
                  {currentSecurity.profile?.admin && <Button fillMode={'flat'} iconClass="fas fa-database" onClick={() => navigate('/catalog')}>
                    {Resources.ReportCatalog}
                  </Button>
                  }
                  <Button
                    themeColor="primary"
                    iconClass={'far fa-life-ring'}
                    onClick={() => { navigate('/help?Section=7');}}
                  >
                    {CommonResources.Button_ContactSupport}
                  </Button>
                </ButtonGroup>
              )} */}
            </Col>
            <Col md={6} className="dashboard-filter-section">
              <SearchBox search={state.search} handleSearchChange={handleSearchChange} className="mt-3 mb-3 pe-4" />
              <div className='d-flex flex-wrap align-items-center'>
                <MultiSelect
                  data={data}
                  onChange={handleMultiselectTagChange}
                  value={selectedTags}
                  placeholder="All types"
                  textField="name"
                  dataItemKey="id"
                  className="tags-dropdown"
                />
                <div>
                  <Switch checked={state.showAllReports} onChange={handleSwitchChange} className="ms-3 me-1" />
                  <label className="switch-label">Show all products</label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              {(loading || error) ? <GenericLoadingPanel loading={true} /> :
                <PanelBar animation={false} expanded={expanded} expandMode="multiple" onSelect={handleSelect} className={'product-catalog'}>
                  {LoadCatalog(loading, error, dataProducts, state, currentSecurity, navigate, accessibleProducts)}
                </PanelBar>
              }
            </Col>
          </Row>
        </Container>
        {panels.length > 0 && currentSecurity.profile?.admin && (
          <JumboBox styleName="admin-section mb-0">
            {renderPanels(Resources.Settings, panels, 'admin-section')}
          </JumboBox>
        )}
      </div>
      {/* <Footer /> */}
    </div>
  ) : (
    <WelcomeNoAuthorized user={currentSecurity.userInfo} />
  );
}

export default React.memo(Dashboard);
