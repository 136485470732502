import React from 'react';
import { CommonResources, Resources } from '../../config/CommonResources';
import Footer from '../shared/Footer';
import { Button } from '@progress/kendo-react-buttons';
import { IUserInfoProps } from '../services/GraphQLShared';
import { Colors } from '../../config/Constants';
import JumboBox from '../shared/JumboBox';
import { OnDemandIcon } from '../helpers/IconLoader';
import { WelcomeHeader } from './Dashboard';

export interface IWelcomeNoAuthorizedProps {
  user: IUserInfoProps | null;
}

function WelcomeNoAuthorized(props: IWelcomeNoAuthorizedProps): React.ReactElement {
  return (
    <div className="app__content">
      <div>
        <JumboBox styleName="full-screen p-5">
          {WelcomeHeader(props.user)}
          <p>
            <OnDemandIcon icon="exclamation-triangle" color={Colors.AlertColor} className="me-2" />
            {CommonResources.Message_UnauthorizeIntro}
          </p>
          <p dangerouslySetInnerHTML={{ __html: Resources.ApplicationContanctTeam }}></p>
          <Button themeColor="primary">
            <OnDemandIcon icon={'life-ring'} className="me-2" />
            {CommonResources.Button_ContactSupport}
          </Button>
        </JumboBox>
      </div>
      <Footer />
    </div>
  );
}

export default React.memo(WelcomeNoAuthorized);
